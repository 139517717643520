/* export default function obtenerNombresCapasASC(urlASC) {
  return new Promise((resolve, reject) => {
    fetch(urlASC)
      .then((response) => response.text())
      .then((data) => {
        let nombresCapas = data.split("\n");
        nombresCapas = nombresCapas.map((capa) => capa.trim());

        let nombresCapasFiltrados = [];

        //Recorre nombresCapas y si encuentra alguna linea que empiece por "planta" añade el contenido de la linea completa a nombresCapasFiltrados, empezará a recorrer desde la linea 26 en adelante
        for (let i = 0; i < nombresCapas.length; i++) {
          if (nombresCapas[i].startsWith("planta") || nombresCapas[i].startsWith("Planta") || nombresCapas[i].startsWith("PLANTA")){
            //Si no contiene ninguna , la añade
              nombresCapasFiltrados.push(nombresCapas[i]);
          }
        }

        console.log(nombresCapasFiltrados);
        resolve(nombresCapasFiltrados);
      })
      .catch((error) => reject(error));
  });
} */


export default function obtenerNombresCapasASC(referenciaCatastral) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(
        "POST",
        "./Request/enc/geojsonPorReferenciaCatastralCifrado.php"
    );
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onload = function () {
        if (xhr.status === 200) {
            /* console.log(JSON.parse(xhr.responseText)[0].asci); */
            let nombresCapas = JSON.parse(xhr.responseText)[0].asci.split("\n")
            console.log(nombresCapas);
             
            nombresCapas = nombresCapas.map((capa) => capa.trim());

          let nombresCapasFiltrados = [];

          //Recorre nombresCapas y si encuentra alguna linea que empiece por "planta" añade el contenido de la linea completa a nombresCapasFiltrados, empezará a recorrer desde la linea 26 en adelante
          for (let i = 0; i < nombresCapas.length; i++) {
            if (nombresCapas[i].startsWith("planta") || nombresCapas[i].startsWith("Planta") || nombresCapas[i].startsWith("PLANTA")){

              //Si no contiene ninguna , la añade
                nombresCapasFiltrados.push(nombresCapas[i]);

            }
          }

        console.log(nombresCapasFiltrados);
        resolve(nombresCapasFiltrados);
        } else if (xhr.status !== 200) {
            reject("Request failed.  Returned status of " + xhr.status);
        }
    };
    xhr.send(`referenciaCatastral=${referenciaCatastral}`);
  });
}

