import { Feature, Map, Overlay, View } from "ol/index.js";
/* import VectorLayer from 'ol/layer/Vector'; */
import { Vector as VectorSource } from "ol/source.js";
import { Point } from "ol/geom.js";
import { Vector as VectorLayer } from "ol/layer.js";
import TileLayer from "ol/layer/WebGLTile.js";
/* import { useGeographic } from "ol/proj.js"; */
import GeoTIFF from "ol/source/GeoTIFF.js";
/* import { set } from "ol/transform.js"; */
import { GeoJSON } from "ol/format.js";
import { Text, Fill, Stroke, Style, Icon } from "ol/style.js";
import { LineString, Polygon } from "ol/geom.js";
import { ScaleLine } from "ol/control.js";
import { Circle } from "ol/style.js";
import Draw from "ol/interaction/Draw.js";
import cargaTodosPuntos from "./Requests/cargaTodosPuntos.js";
import cargaPuntosCalleSeleccionada from "./Requests/cargaPuntosCalleSeleccionada.js";
import coordenadasPorReferenciaCatastral from "./Requests/coordenadasPorReferenciaCatastral.js";
import cargaTipoViaPorReferenciaCatastral from "./Requests/cargaTipoViaPorReferenciaCatastral.js";
import iniciar from "./Requests/cargaDatosIniciales.js";
import cargaDatosASC from "./Requests/cargaDatosASC.js";
import cargaPuntosCallePorVia from "./Requests/cargaPuntosCallePorVia.js";
/* import DxfParser from "dxf-parser"; */
import { getArea, getLength } from "ol/sphere.js";
import { unByKey } from "ol/Observable.js";
import { DragRotate, defaults as defaultInteractions } from "ol/interaction.js";
import DragZoom from "ol/interaction/DragZoom";
import { shiftKeyOnly } from "ol/events/condition";
import XYZ from "ol/source/XYZ";
/* import { getCenter } from "ol/extent"; */
import obtenerNombresCapasASC from "./Requests/obtenerNombresCapasASC.js";
import geojsonPorReferenciaCatastral from "./Requests/geojsonPorReferenciaCatastral.js";


let centroOrtofoto = "";

document.addEventListener("DOMContentLoaded", () => {
  //Obtengo el nombre del municipio del archivo inicializacione.json con fetch
  fetch("./inicializacion.json")
    .then((response) => response.json())
    .then((data) => {
      document.getElementById("nombreMunicipio").innerHTML = data.nombreMunicipio;
      centroOrtofoto = data.centroOrtofoto;
    });
  });

iniciar();

//Importo circle
import {
  Circle as CircleStyle,
  Fill as FillStyle,
  Stroke as StrokeStyle,
  Style as StyleStyle,
} from "ol/style.js";
/* import { rotate, set } from "ol/transform.js"; */
import cargaReferenciaCatastralPorVia from "./Requests/cargaReferenciaCatastralPorVia.js";
/* import { set } from "ol/transform.js"; */
/* import { set } from "ol/transform.js"; */
/* import { add } from "ol/coordinate.js"; */

const source = new GeoTIFF({
  sources: [
    {
      url: "./ortofotos/ortofoto.tif",
    },
  ],
  convertToRGB: true,
});

const map = new Map({
  interactions: defaultInteractions({ doubleClickZoom: false }).extend([
    new DragRotate(),
  ]),
  target: "map",
  layers: [
    new TileLayer({
      source: source,
    }),
    new VectorLayer({
      source: new VectorSource({
        /*  features: [new Feature(point)], */
      }),
      /* style: {
        "circle-radius": 10,
        "circle-fill-color": "red",
      }, */
    }),
  ],
  view: source.getView(),
});

// Añado la escala
map.addControl(new ScaleLine());

const dragRotate = new DragRotate({
  condition: shiftKeyOnly,
});

map.addInteraction(dragRotate);

// Desactiva la interacción de zoom por defecto cuando se mantiene pulsada la tecla shift
map.getInteractions().forEach(function (interaction) {
  if (interaction instanceof DragZoom) {
    interaction.setActive(false);
  }
});

//Hago un console.log cuando se empieza a cargar el mapa
map.once("precompose", function (event) {
  //Obtengo el title dee los botones de zoom y rotacion
  const zoomIn = document.getElementsByClassName("ol-zoom-in")[0];
  const zoomOut = document.getElementsByClassName("ol-zoom-out")[0];
  const rotate = document.getElementsByClassName("ol-rotate-reset")[0];

  //Cambio el title de los botones de zoom y rotacion
  zoomIn.title = "Ampliar";
  zoomOut.title = "Reducir";
  rotate.title = "Rotar";
});









/* const geoJsonObject =
  "./GML_Parcelas.geojson"; */

/* 
//Obtiene el geojson de la ruta de geoJsonObject
fetch(geoJsonObject)
  .then((response) => response.json())
  .then((data) => {
    const geojsonFormat = new GeoJSON();
    const features = geojsonFormat.readFeatures(data);

    // Filtrar las características basadas en la referencia catastral deseada
    const filteredFeatures = features.filter((feature) => {
      const cpLabel = feature.get("label");
      const referenciaCatastral = "5948510UP9054N";
      return cpLabel === referenciaCatastral;
    });

    const vectorSource = new VectorSource({
      features: filteredFeatures,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: {
        "fill-color": "rgba(0, 0, 255, 0.15)",
        "stroke-color": "rgba(0, 0, 0, 0.6)",
        "stroke-line-dash": [8, 4],
        "stroke-width": 2,
        "icon-opacity": 0.7,
      },
      title: "Parcelas",
    });

    map.addLayer(vectorLayer);

    // Calcular el centro del vectorLayer
    const extent = vectorSource.getExtent();
    const center = getCenter(extent);

    // Nueva layer con un texto sobre el mapa
    const vectorLayer2 = new VectorLayer({
      source: new VectorSource({
        features: [
          new Feature({
            geometry: new Point(center),
            text: "Texto de prueba",
          }),
        ],
      }),
      style: function (feature) {
        const zoom = map.getView().getZoom();
        if (zoom >= 6) {
          return new Style({
            text: new Text({
              text: feature.get("text"),
              font: "bold 14px Arial",
              fill: new Fill({
                color: "#000",
              }),
              stroke: new Stroke({
                color: "#fff",
                width: 3,
              }),
              textAlign: "center", // Centrar el texto
              textBaseline: "middle", // Centrar el texto verticalmente
            }),
          });
        } else {
          return null;
        }
      },
    });
    map.addLayer(vectorLayer2);
  });
 */

// Evento al hacer click en el vectorLayer cargado del geojson muestra las propiedades de la parcela
/* map.on("click", async function (event) {
  const feature = map.getFeaturesAtPixel(event.pixel)[0];
  if (
    feature &&
    feature.getGeometry().getType() === "MultiPolygon" &&
    !document.getElementById("medir").classList.contains("activo")
  ) {
    //Ruta al archivo asc
    const asc = "5948510UP9054N0001RD.asc";
    let codVia = "",
      sg = "",
      nomVia = "",
      fecha = "",
      suppar = "",
      supcons = "";

    //Guardo los valores del archivo asc en una variable
    await fetch(asc)
      .then((response) => response.text())
      .then((data) => {
        // Meto cada línea del archivo asc en una posición de un array
        const lines = data.split("\n");

        codVia = lines[4];
        sg = lines[5];
        nomVia = lines[6];
        fecha = lines[21];
        suppar = lines[22];
        supcons = lines[25];
      });

    //Para la variable supcons, muevo los decimales 3 posiciones a la izquierda y quitamos los 0 a la izquierda
    supcons = parseInt(supcons);
    supcons = `${supcons}`;
    supcons =
      supcons.substring(0, supcons.length - 2) +
      "." +
      supcons.substring(supcons.length - 2);

    // Muestro un modal con las propiedades de la parcela y lo añado al html
    const modal = `
      <!-- Main modal -->
      <div id="modalInfoParcela" tabindex="-1" class="fixed inset-0 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg shadow-lg w-full max-w-lg">
          <!-- Modal content -->
          <div class="p-4 md:p-5">
            <!-- Modal header -->
            <div class="flex items-center justify-between border-b pb-4">
              <h3 class="text-xl font-semibold text-gray-900">
                Propiedades de la parcela ${feature.get("label")}
              </h3>
              <button type="button" id="cerrarModal" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <!-- Modal body -->
            <div class="p-4 md:p-5 space-y-4">
              <p class="text-base leading-relaxed text-gray-700">
                <b>Código de vía: </b>${codVia}<br>
                <b>Siglas: </b>${sg}<br>
                <b>Nombre de vía: </b>${nomVia}<br>
                <b>Fecha actualización: </b>${fecha}<br>
                <b>Superficie parcela: </b>${parseInt(suppar)} m2<br>
                <b>Superficie construida: </b> ${supcons} m2<br>
              </p>
            </div>
          </div>
        </div>
      </div>
      `;

    const modalContainer = document.getElementsByTagName("body")[0];
    modalContainer.insertAdjacentHTML("beforeend", modal);

    // Al hacer click en el boton cerrar modal, se elimina el modal del html
    const cerrarModal = document.getElementById("cerrarModal");
    cerrarModal.addEventListener("click", () => {
      const modal = document.getElementById("modalInfoParcela");
      modal.remove();
    });
  }
}); */

let zoomSet = false; // Variable para controlar si el zoom ya ha sido establecido

map.on("precompose", function () {
  if (!zoomSet) {
    //Obtengo la resolucion del monitor del usuario horizontal y vertical
    const resolucionHorizontal = window.screen.width;
    const resolucionVertical = window.screen.height;

    //Obtengo el factor de escala del navegador
    const factorEscala = window.devicePixelRatio;

    if (
      resolucionHorizontal >= 1920 &&
      resolucionVertical >= 1080 &&
      factorEscala >= 0.88 &&
      factorEscala <= 1.12
    ) {
      map.getView().setZoom(1.05);
    } else {
      map.getView().setZoom(0.2);
    }

    //Establezco el centro del mapa
    map.getView().setCenter(centroOrtofoto);
    zoomSet = true;
  }
});

const element = document.getElementById("popup");

const popup = new Overlay({
  element: element,
  stopEvent: false,
});
map.addOverlay(popup);

function formatCoordinate(coordinate) {
  return `
    <table class="float-right">
      <tbody>
        <tr><td><b>X: </b>${coordinate[0].toFixed(
          2
        )}&nbsp&nbsp&nbsp<b>Y: </b>${coordinate[1].toFixed(2)}</td></tr>
      </tbody>
    </table>`;
}

const info = document.getElementById("info");
map.on("moveend", function () {
  const view = map.getView();
  const center = view.getCenter();
  info.innerHTML = formatCoordinate(center);
});

map.on("pointermove", function (event) {
  const coordinate = map.getCoordinateFromPixel(event.pixel);
  info.innerHTML = formatCoordinate(coordinate);
});

//Maneja el evento click en la capa de puntos
/* let popover;
map.on("click", function (event) {
  if (popover) {
    popover.dispose();
    popover = undefined;
  }
  const feature = map.getFeaturesAtPixel(event.pixel)[0];
  if (!feature) {
    return;
  }
  const coordinate = feature.getGeometry().getCoordinates();
  popup.setPosition([
    coordinate[0] + Math.round(event.coordinate[0] / 360) * 360,
    coordinate[1],
  ]);

  popover = new bootstrap.Popover(element, {
    container: element.parentElement,
    content: formatCoordinate(coordinate),
    html: true,
    offset: [0, 20],
    placement: "top",
    sanitize: false,
  });
  popover.show();
}); */

map.on("pointermove", function (event) {
  const type = map.hasFeatureAtPixel(event.pixel) ? "pointer" : "inherit";
  map.getViewport().style.cursor = type;
});

//Hace una petición al servidor cuando se pulsa enviar y hay una referencia catastral completada
//Muestra las coordenadas en el mapa y pone un punto en esas coordenadas

const enviar = document.getElementById("enviar");

enviar.addEventListener("click", () => {
  //Comprueba que la referencia catastral coincida con alguna del datalist
  const referenciaCatastral = document.getElementById(
    "valorReferenciaCatastral"
  );
  let referenciaCatastralCorrecta = false;
  const opcionesReferenciaCatastral = document.getElementById(
    "tiposReferenciaCatastral"
  ).childNodes;
  opcionesReferenciaCatastral.forEach((element) => {
    if (referenciaCatastral.value == element.value) {
      referenciaCatastralCorrecta = true;
    }
  });

  //Si la referencia catastral es correcta, muestro las coordenadas
  if (referenciaCatastralCorrecta) {
    //Recupero lo que me devuelve la promesa de la funcion coordenadasPorReferenciaCatastral
    const coordenadas = coordenadasPorReferenciaCatastral();

    //Si la promesa se resuelve correctamente, muestro las coordenadas y pongo un punto en el mapa en esas coordenadas

    coordenadas.then((coordenadas) => {
      //Si alguna de las coordenadas empieza por 0, le quito el 0
      if (coordenadas[0].startsWith("0")) {
        coordenadas[0] = coordenadas[0].substring(1);
      }

      //Cambio las , por . para que se pueda leer como numero
      coordenadas[0] = coordenadas[0].replace(",", ".");
      coordenadas[1] = coordenadas[1].replace(",", ".");

      const point2 = new Point([coordenadas[0], coordenadas[1]]);
      const feature2 = new Feature(point2);

      const style = new Style({
        image: new Circle({
          radius: 10,
          fill: new Fill({ color: "orange" }),
          stroke: new Stroke({ color: "black", width: 2 }),
        }),
      });
      feature2.setStyle(style);

      //Añado una feature con la label de la referencia catastral
      feature2.set("label", referenciaCatastral.value);
      feature2.set("simbolo", "simbolo");

      // Dibujar el punto en el mapa
      const newLayer = new VectorLayer({
        source: new VectorSource(),
        name: "puntoSeleccionado",
      });
      setTimeout(() => {
        map.addLayer(newLayer);
      }, 200);
      newLayer.getSource().addFeature(feature2);

      newLayer.setZIndex(3);

      //Compruebo si ya existe una capa de nombre puntoSeleccionado y si es así, la elimino
      const capapuntoSeleccionado = map
        .getLayers()
        .getArray()
        .find((layer) => layer.get("name") === "puntoSeleccionado");
      const capaPuntosDeLaMismaCalle = map
        .getLayers()
        .getArray()
        .find((layer) => layer.get("name") === "puntosDeLaMismaCalle");
      if (capapuntoSeleccionado && capaPuntosDeLaMismaCalle) {
        map.removeLayer(capapuntoSeleccionado);
        map.removeLayer(capaPuntosDeLaMismaCalle);
      }

      // Animación de zoom gradual
      const targetZoom = 7;
      const duration = 2000; // Duración de la animación en milisegundos
      const startZoom = map.getView().getZoom();
      const start = Date.now();
      const startCenter = map.getView().getCenter();
      const targetCenter = point2.getCoordinates();

      function animateZoom() {
        const elapsed = Date.now() - start;
        const progress = Math.min(elapsed / duration, 1);
        const currentZoom =
          startZoom + (targetZoom - startZoom) * easeOutCubic(progress);
        map.getView().setZoom(currentZoom);

        const currentCenter = [
          startCenter[0] +
            (targetCenter[0] - startCenter[0]) * easeOutCubic(progress),
          startCenter[1] +
            (targetCenter[1] - startCenter[1]) * easeOutCubic(progress),
        ];
        map.getView().setCenter(currentCenter);

        if (progress < 1) {
          requestAnimationFrame(animateZoom);
        }
      }

      animateZoom();
    });

    function easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    }

    // Ejecuto la funcion cargaPuntosCalleSeleccionada pasandole la referencia catastral y hago un console.log del resultado de la promesa
    const puntos = cargaPuntosCalleSeleccionada(referenciaCatastral.value);
    puntos.then((puntos) => {
      // Dibujo una nueva capa de puntos con todos los puntos de la calle seleccionada
      const pointFeatures = puntos.map((puntoIndividual) => {
        //Si alguna de las coordenadas empieza por 0, le quito el 0
        if (puntoIndividual.coordenadaX.startsWith("0")) {
          puntoIndividual.coordenadaX =
            puntoIndividual.coordenadaX.substring(1);
        }

        if (puntoIndividual.coordenadaY.startsWith("0")) {
          puntoIndividual.coordenadaY =
            puntoIndividual.coordenadaY.substring(1);
        }

        //Cambio las , por . para que se pueda leer como numero
        puntoIndividual.coordenadaX = puntoIndividual.coordenadaX.replace(
          ",",
          "."
        );
        puntoIndividual.coordenadaY = puntoIndividual.coordenadaY.replace(
          ",",
          "."
        );

        const point = new Point([
          parseFloat(puntoIndividual.coordenadaX),
          parseFloat(puntoIndividual.coordenadaY),
        ]);

        const feature = new Feature(point);

        // Añadir estilos al punto
        const style = new Style({
          image: new Circle({
            radius: 7,
            fill: new Fill({ color: "yellow" }),
            stroke: new Stroke({ color: "black", width: 2 }),
          }),
        });
        feature.setStyle(style);

        //Uno la referencia catastral 1 y 2
        let referenciaCatastralCompelta =
          puntoIndividual.referenciaCatastral1 +
          puntoIndividual.referenciaCatastral2;

        // Set the catastral reference as the label of the point
        feature.set("label", referenciaCatastralCompelta);
        feature.set("simbolo", "simbolo");

        return feature;
      });

      const vectorSource = new VectorSource({
        features: pointFeatures,
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
        name: "puntosDeLaMismaCalle",
      });

      vectorLayer.setZIndex(2);

      map.addLayer(vectorLayer);

      //Mostrar esta capa solo cuando el zoom sea mayor a 4
      map.on("moveend", function () {
        const zoom = map.getView().getZoom();
        if (zoom >= 4) {
          vectorLayer.setVisible(true);
        } else {
          vectorLayer.setVisible(false);
        }
      });
    });

    //Obtengo los datos de la via a partir de la referencia catastral
    const tipoVia = cargaTipoViaPorReferenciaCatastral();

    tipoVia.then((tipoVia) => {
      //Si la promesa se resuelve correctamente, muestro el tipo de via en el input
      if (tipoVia) {
        console.log(tipoVia);
        let calle = tipoVia[0].nombreCalle,
          letra = tipoVia[0].letraCalle,
          numero = tipoVia[0].numeroCalle,
          inicialesVia = tipoVia[0].tipoVia;

        console.log(letra);

        const nombreViaCompuesto = `${inicialesVia}, ${calle}`;
        const numeroViaCompuesto = `${numero}${letra === " " ? "" : letra}`;

        console.log(nombreViaCompuesto);
        console.log(numeroViaCompuesto);

        document.getElementById("valorNombreVia").value = nombreViaCompuesto;

        //Hago una petición al servidor para rellenar el datalist de numeros de via a partir del nombreViaCompuesto
        const xhr = new XMLHttpRequest();
        xhr.open(
          "POST",
          "./Request/enc/cargaNumerosDeViaCifrado.php"
        );
        xhr.setRequestHeader(
          "Content-Type",
          "application/x-www-form-urlencoded"
        );
        xhr.onload = function () {
          if (xhr.status === 200) {
            const respuesta = JSON.parse(xhr.responseText);

            //Si la respuesta es correcta, relleno el datalist
            if (respuesta) {
              //Borro los hijos del datalist
              const datalist = document.getElementById("numerosVias");
              while (datalist.firstChild) {
                datalist.removeChild(datalist.firstChild);
              }

              //Relleno el datalist con los numeros de via
              respuesta.forEach((element) => {
                const option = document.createElement("option");
                option.value = element.numeroCalle;
                datalist.appendChild(option);
              });
            }
          } else if (xhr.status !== 200) {
            alert("Request failed.  Returned status of " + xhr.status);
          }
        };

        xhr.send(`nombreCalle=${nombreViaCompuesto}`);

        document.getElementById("valorNumeroVias").value = numeroViaCompuesto;
      } else {
      }
    });
  } else {
    //Compruebo que los inputs nombre de via, numero de via y tipo de via coincidan con alguno de los del datalist

    const nombreVia = document.getElementById("valorNombreVia");
    const numeroVia = document.getElementById("valorNumeroVias");
    let nombreViaCorrecto = false,
      numeroViaCorrecto = false;

    const opcionesNombreVia = document.getElementById("nombresVias").childNodes;
    opcionesNombreVia.forEach((element) => {
      if (nombreVia.value == element.value) {
        nombreViaCorrecto = true;
      }
    });

    const opcionesNumeroVia = document.getElementById("numerosVias").childNodes;
    opcionesNumeroVia.forEach((element) => {
      if (numeroVia.value == element.value) {
        numeroViaCorrecto = true;
      }
    });

    //Si los inputs son correctos, lanzo la peticion al servidor para que me devuelva las coordenadas
    if (nombreViaCorrecto && numeroViaCorrecto) {
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "./Request/enc/cargaCoordenadasPorViaCifrado.php"
      );
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.onload = function () {
        if (xhr.status === 200) {
          const respuesta = JSON.parse(xhr.responseText);

          //Si la respuesta es correcta, muestro las coordenadas
          if (respuesta) {
            //Si alguna de las coordenadas empieza por 0, le quito el 0
            if (respuesta[0].coordenadaX.startsWith("0")) {
              respuesta[0].coordenadaX = respuesta[0].coordenadaX.substring(1);
            }
            if (respuesta[0].coordenadaY.startsWith("0")) {
              respuesta[0].coordenadaY = respuesta[0].coordenadaY.substring(1);
            }

            //Cambio las , por . para que se pueda leer como numero
            respuesta[0].coordenadaX = respuesta[0].coordenadaX.replace(
              ",",
              "."
            );

            respuesta[0].coordenadaY = respuesta[0].coordenadaY.replace(
              ",",
              "."
            );

            const point2 = new Point([
              respuesta[0].coordenadaX,
              respuesta[0].coordenadaY,
            ]);

            const feature2 = new Feature(point2);

            map.getLayers().getArray()[1].getSource().addFeature(feature2);

            // Animación de zoom gradual al punto
            const targetZoom = 7;
            const duration = 2000; // Duración de la animación en milisegundos
            const startZoom = map.getView().getZoom();
            const start = Date.now();
            const startCenter = map.getView().getCenter();
            const targetCenter = [
              respuesta[0].coordenadaX,
              respuesta[0].coordenadaY,
            ];

            function easeOutCubic(t) {
              return 1 - Math.pow(1 - t, 3);
            }

            function animateZoom() {
              const elapsed = Date.now() - start;
              const progress = Math.min(elapsed / duration, 1);
              const currentZoom =
                startZoom + (targetZoom - startZoom) * easeOutCubic(progress);
              map.getView().setZoom(currentZoom);

              const currentCenter = [
                startCenter[0] +
                  (targetCenter[0] - startCenter[0]) * easeOutCubic(progress),
                startCenter[1] +
                  (targetCenter[1] - startCenter[1]) * easeOutCubic(progress),
              ];
              map.getView().setCenter(currentCenter);

              if (progress < 1) {
                requestAnimationFrame(animateZoom);
              }
            }

            animateZoom();

            //Ejecuto cargaReferenciaCatastralPorVia() y obtengo el resultado de la promesa
            const referenciaCatastral = cargaReferenciaCatastralPorVia();

            referenciaCatastral.then((referenciaCatastral) => {
              document.getElementById("valorReferenciaCatastral").value =
                referenciaCatastral;

              //Dibujo una nueva capa de puntos con todos los puntos de la calle seleccionada
              /* const puntos = cargaPuntosCalleSeleccionada(referenciaCatastral); */
              const valorCalle =
                document.getElementById("valorNombreVia").value;
              const puntos = cargaPuntosCallePorVia(valorCalle);

              puntos.then((puntos) => {
                const pointFeatures = puntos.map((puntoIndividual) => {
                  //Si alguna de las coordenadas empieza por 0, le quito el 0
                  if (puntoIndividual.coordenadaX.startsWith("0")) {
                    puntoIndividual.coordenadaX =
                      puntoIndividual.coordenadaX.substring(1);
                  }

                  if (puntoIndividual.coordenadaY.startsWith("0")) {
                    puntoIndividual.coordenadaY =
                      puntoIndividual.coordenadaY.substring(1);
                  }

                  //Cambio las , por . para que se pueda leer como numero
                  puntoIndividual.coordenadaX =
                    puntoIndividual.coordenadaX.replace(",", ".");
                  puntoIndividual.coordenadaY =
                    puntoIndividual.coordenadaY.replace(",", ".");

                  const point = new Point([
                    parseFloat(puntoIndividual.coordenadaX),
                    parseFloat(puntoIndividual.coordenadaY),
                  ]);

                  const feature = new Feature(point);

                  // Añadir estilos al punto
                  const style = new Style({
                    image: new Circle({
                      radius: 7,
                      fill: new Fill({ color: "yellow" }),
                      stroke: new Stroke({ color: "black", width: 2 }),
                    }),
                  });
                  feature.setStyle(style);

                  //Uno la referencia catastral 1 y 2
                  let referenciaCatastralCompelta =
                    puntoIndividual.referenciaCatastral1 +
                    puntoIndividual.referenciaCatastral2;

                  // Set the catastral reference as the label of the point
                  feature.set("label", referenciaCatastralCompelta);
                  feature.set("simbolo", "simbolo");

                  return feature;
                });

                const vectorSource = new VectorSource({
                  features: pointFeatures,
                });

                const vectorLayer = new VectorLayer({
                  source: vectorSource,
                  name: "puntosDeLaMismaCalle",
                });

                vectorLayer.setZIndex(2);

                map.addLayer(vectorLayer);

                //Mostrar esta capa solo cuando el zoom sea mayor a 4
                map.on("moveend", function () {
                  const zoom = map.getView().getZoom();
                  if (zoom >= 4) {
                    vectorLayer.setVisible(true);
                  } else {
                    vectorLayer.setVisible(false);
                  }
                });
              });

              //Obtengo las coordenadas de la calle seleccionada y muestro en esas coordenadas un punto de color naraja
              const coordenadas = coordenadasPorReferenciaCatastral();

              //Si la promesa se resuelve correctamente, muestro las coordenadas y pongo un punto en el mapa en esas coordenadas
              coordenadas.then((coordenadas) => {
                //Si alguna de las coordenadas empieza por 0, le quito el 0
                if (coordenadas[0].startsWith("0")) {
                  coordenadas[0] = coordenadas[0].substring(1);
                }

                //Cambio las , por . para que se pueda leer como numero
                coordenadas[0] = respuesta[0].coordenadaX.replace(",", ".");
                coordenadas[1] = respuesta[0].coordenadaY.replace(",", ".");

                const point2 = new Point([coordenadas[0], coordenadas[1]]);
                const feature2 = new Feature(point2);

                const style = new Style({
                  image: new Circle({
                    radius: 10,
                    fill: new Fill({ color: "orange" }),
                    stroke: new Stroke({ color: "black", width: 2 }),
                  }),
                });
                feature2.setStyle(style);

                //Añado una feature con la label de la referencia catastral
                feature2.set(
                  "label",
                  document.getElementById("valorReferenciaCatastral").value
                );
                feature2.set("simbolo", "simbolo");

                // Dibujar el punto en el mapa
                const newLayer = new VectorLayer({
                  source: new VectorSource(),
                  name: "puntoSeleccionado",
                });
                setTimeout(() => {
                  map.addLayer(newLayer);
                }, 200);
                newLayer.getSource().addFeature(feature2);
                newLayer.setZIndex(3);
              });

              //Compruebo si ya existe una capa de nombre puntoSeleccionado y si es así, la elimino
              const capapuntoSeleccionado = map
                .getLayers()
                .getArray()
                .find((layer) => layer.get("name") === "puntoSeleccionado");
              const capaPuntosDeLaMismaCalle = map
                .getLayers()
                .getArray()
                .find((layer) => layer.get("name") === "puntosDeLaMismaCalle");
              if (capapuntoSeleccionado && capaPuntosDeLaMismaCalle) {
                map.removeLayer(capapuntoSeleccionado);
                map.removeLayer(capaPuntosDeLaMismaCalle);
              }
            });
          }
          //Si no, muestro el error
          else {
            alert("No se han encontrado las coordenadas");
          }
        } else if (xhr.status !== 200) {
          alert("Request failed.  Returned status of " + xhr.status);
        }
      };

      let nombreCalle = "",
        numeroCalle = "",
        tipoVia = "",
        letraCalle = "";

      //Separo el tipo de via y el nombre de la via en dos variables
      if (nombreVia.value != "") {
        const nombreCalleArray = nombreVia.value.split(",");
        tipoVia = nombreCalleArray[0];
        nombreCalle = nombreCalleArray[1].trim();
      }

      //Separo el numero de la calle y la letra en dos variables
      if (numeroVia.value != "") {
        const numeroViaArray = numeroVia.value.split(/(\d+)/).filter(Boolean);
        numeroCalle = numeroViaArray[0];
        letraCalle = numeroViaArray[1];
      }

      xhr.send(
        `nombreVia=${nombreCalle}&numeroVia=${numeroCalle}&tipoVia=${tipoVia}&letraCalle=${letraCalle}`
      );
    }
  }
});

document.addEventListener("DOMContentLoaded", function () {
  // Dibujo una nueva capa de puntos con todas las coordenadas de las parcelas de la base de datos
  let puntos = cargaTodosPuntos();

  puntos.then((puntos) => {
    const pointFeatures = puntos.map((puntoIndividual) => {
      //Si alguna de las coordenadas empieza por 0, le quito el 0
      if (puntoIndividual.coordenadaX.startsWith("0")) {
        puntoIndividual.coordenadaX = puntoIndividual.coordenadaX.substring(1);
      }

      if (puntoIndividual.coordenadaY.startsWith("0")) {
        puntoIndividual.coordenadaY = puntoIndividual.coordenadaY.substring(1);
      }

      //Cambio las , por . para que se pueda leer como numero
      puntoIndividual.coordenadaX = puntoIndividual.coordenadaX.replace(
        ",",
        "."
      );
      puntoIndividual.coordenadaY = puntoIndividual.coordenadaY.replace(
        ",",
        "."
      );

      const posicion = puntoIndividual.pos;
      let offset = {
        1: { offsetX: 13, offsetY: 13 },
        2: { offsetX: -13, offsetY: 13 },
        3: { offsetX: 13, offsetY: -13 },
        4: { offsetX: -13, offsetY: -13 },
      };

      let { offsetX, offsetY } = offset[posicion] || { offsetX: 13, offsetY: 13 };

      const numeroCalleConLetra =
        puntoIndividual.numeroCalle + puntoIndividual.letraCalle;

      const point = new Point([
        parseFloat(puntoIndividual.coordenadaX),
        parseFloat(puntoIndividual.coordenadaY),
      ]);

      const feature = new Feature(point);

      // Añadir estilos al punto
      const style = new Style({
        image: new Icon({
          src: `./simbolosWebp/${puntoIndividual.simbolo}.webp`,
        }),
        text: new Text({
          text: numeroCalleConLetra,
          offsetY: offsetY, // Adjust the vertical position of the text
          offsetX: offsetX, // Adjust the horizontal position of the text
          fill: new Fill({
            color: "white", // Set the text color
          }),
          stroke: new Stroke({
            color: "black", // Set the stroke color
            width: 3, // Set the stroke width
          }),
          font: "bold 11px Arial", // Set the font style to bold
        }),
      });

      feature.setStyle(style);

      //Uno la referencia catastral 1 y 2
      let referenciaCatastralCompelta =
        puntoIndividual.referenciaCatastral1 +
        puntoIndividual.referenciaCatastral2;

      // Set the catastral reference as the label of the point
      feature.set("label", referenciaCatastralCompelta);
      feature.set("simbolo", "simbolo");

      return feature;
    });

    const vectorSource = new VectorSource({
      features: pointFeatures,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    vectorLayer.setZIndex(1);

    vectorLayer.setVisible(false);

    vectorLayer.set('name', "layerNumerosPolicia")

    map.addLayer(vectorLayer);

    //Mostrar esta capa solo cuando el zoom sea mayor a 6
    map.on("moveend", function () {
      const zoom = map.getView().getZoom();
      if (zoom >= 5) {
        vectorLayer.setVisible(true);
      } else {
        vectorLayer.setVisible(false);
      }
    });
  });
});

// Al hacer click en un punto se hace un console.log con la referencia catastral
/* map.on("click", function (event) {
  const feature = map.getFeaturesAtPixel(event.pixel)[0];
  if (feature && feature.getGeometry().getType() === "Point") {
    console.log(feature.get("label"));
  }
});  */

//Creo nueva capa a partir de un geojson
const geoJsonCalles = "./textosCalles.geojson";

//Obtiene el geojson de la ruta de geoJsonCalles
fetch(geoJsonCalles)
  .then((response) => response.json())
  .then((data) => {
    const geojsonFormat = new GeoJSON();
    const features = geojsonFormat.readFeatures(data);

    const vectorSource = new VectorSource({
      features: features,
    });

    //Recorro cada linea del geojson y si la capa es PG-10M-TO, recupero el valor del campo Text y las coordenadas y creo una capa con todos los textos
    let vectorLayerTexto;
    const featuresTexto = [];

    features.forEach((element) => {
      if (element.values_.Layer == "PG-10M-TO") {
        featuresTexto.push(
          new Feature({
            geometry: new Point([
              element.values_.geometry.flatCoordinates[0],
              element.values_.geometry.flatCoordinates[1],
            ]),
            text: element.values_.Text.replace(/\s+/g, " "), // Replace multiple spaces with a single space
            rotation:
              (element.values_.geometry.flatCoordinates[2] * Math.PI) / 180, // Convert radians to degrees
            scale: element.values_.geometry.flatCoordinates[3],
          })
        );
      }
    });

    vectorLayerTexto = new VectorLayer({
      source: new VectorSource({
        features: featuresTexto,
      }),
      style: function (feature) {
        const zoom = map.getView().getZoom();
        if (zoom >= 5.5) {
          const text = feature.get("text");
          const font = "bold 10px Arial";
          const textWidth = getTextWidth(text, font);
          const textHeight = 8;
          const textOffsetX =
            (textWidth / 2) *
            ((0.162 / Math.pow(map.getView().getResolution(), 0.94)) *
              feature.get("scale")); // Scale the textOffsetX with the view
          const textOffsetY =
            (-textHeight / 2) *
            ((0.162 / Math.pow(map.getView().getResolution(), 0.94)) *
              feature.get("scale")); // Scale the textOffsetY with the view

          return new Style({
            text: new Text({
              text: text,
              font: font,
              fill: new Fill({
                color: "#000",
              }),
              offsetX: textOffsetX,
              offsetY: textOffsetY, // Set the offset in the Y direction
              rotation: -feature.get("rotation"),
              textAlign: "center",
              textBaseline: "middle",
              rotateWithView: true, // Rotate the text with the view
              scale:
                (0.162 / Math.pow(map.getView().getResolution(), 0.94)) *
                feature.get("scale"), // Scale the text with the view
            }),
          });
        } else {
          return null;
        }
      },
    });

    function getTextWidth(text, font) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    }

    vectorLayerTexto.setZIndex(2);

    map.addLayer(vectorLayerTexto);
  });

//Recorro cada linea del geojson y si la capa es PG-10M-LI o PG-10M-LT, recupero las coordenadas y creo una capa de poligonos con uno por cada linea del geojson
/* const geoJsonParcelas = "CALLEJE.geojson";

//Obtiene el geojson de la ruta de geoJsonParcelas
fetch(geoJsonParcelas)
  .then((response) => response.json())
  .then((data) => {
    const geojsonFormat = new GeoJSON();
    const features = geojsonFormat.readFeatures(data);

    //Recorro el array y agrupo en un array de arrays de 2 elementos cada uno
    let arrayCoordenadas = [];

    features.forEach(element => {
      if(element.values_.Layer == "PG-10M-LI" || element.values_.Layer == "PG-10M-LT"){
        arrayCoordenadas.push([element.values_.geometry.flatCoordinates]);
      }
    });

    console.log(arrayCoordenadas);

    //Recorro cada array dentro del array arrayCoordenadas y transformo cada uno de esos arrays para que cada 2 posiciones se formen un array de 2 elementos dentro del que ya teniamos
    let arrayCoordenadas2 = [];

    arrayCoordenadas.forEach(element => {
      let arrayCoordenadas3 = [];
      for(let i = 0; i < element[0].length; i+=2){
        arrayCoordenadas3.push([element[0][i], element[0][i+1]]);
      }
      arrayCoordenadas2.push(arrayCoordenadas3);
    });

 
    //Recorro cada array dentro del array arrayCoordenadas2 y creo una capa de poligonos con uno por cada array
    const source = new VectorSource();

    arrayCoordenadas2.forEach(coordinates => {
      source.addFeature(new Feature({
        geometry: new Polygon([coordinates]),
      }));
    });

    const vectorLayer4 = new VectorLayer({
      source: source,
      style: {
        "stroke-color": "rgba(0, 0, 0, 0.7)",
        "stroke-width": 2,
        "fill-color": "rgba(0, 0, 255, 0.7)",
      },
    });
    
    map.addLayer(vectorLayer4);
  }); */

// Al hacer click en el boton de id ampliar, se hace zoom a la referencia catastral introducida
const botonAmpliar = document.getElementById("ampliar");

// Hago un zoom de 7 a la referencia catastral introducida
botonAmpliar.addEventListener("click", () => {
  if (document.getElementById("valorReferenciaCatastral").value !== "") {
    //Obtengo las coordenadas de las coordenadas de la referencia catastral introducida y hago un console.log
    const coordenadas = coordenadasPorReferenciaCatastral();

    //Si la promesa se resuelve correctamente, muestro las coordenadas en un console.log
    coordenadas.then((coordenadas) => {
      //Si alguna de las coordenadas empieza por 0, le quito el 0
      if (coordenadas[0].startsWith("0")) {
        coordenadas[0] = coordenadas[0].substring(1);
      }

      //Cambio las , por . para que se pueda leer como numero
      coordenadas[0] = coordenadas[0].replace(",", ".");
      coordenadas[1] = coordenadas[1].replace(",", ".");

      //Hago un zoom de 8 a esas coordenadas
      map.getView().setCenter([coordenadas[0], coordenadas[1]]);
      map.getView().setZoom(8);
    });
  } else if (
    document.getElementById("valorNombreVia").value !== "" &&
    document.getElementById("valorNumeroVias").value !== ""
  ) {
    const referenciaCatastral = cargaReferenciaCatastralPorVia();

    referenciaCatastral.then((referenciaCatastral) => {
      document.getElementById("valorReferenciaCatastral").value =
        referenciaCatastral;

      //Obtener coordenadas de la referencia catastral, les quito el 0 inial si lo tienen y le cambio las , por .
      const coordenadas = coordenadasPorReferenciaCatastral();

      coordenadas.then((coordenadas) => {
        //Si alguna de las coordenadas empieza por 0, le quito el 0
        if (coordenadas[0].startsWith("0")) {
          coordenadas[0] = coordenadas[0].substring(1);
        }

        //Cambio las , por . para que se pueda leer como numero
        coordenadas[0] = coordenadas[0].replace(",", ".");
        coordenadas[1] = coordenadas[1].replace(",", ".");

        //Hago un zoom de 8 a esas coordenadas
        map.getView().setCenter([coordenadas[0], coordenadas[1]]);
        map.getView().setZoom(8);
      });
    });
  }
});



const botonObtenerInformacion = document.getElementById("obtenerInformacion");
let pointerMoveListener = null;
let lastExecutionTime = 0;
let modo = "click"; // Inicialmente configurado en modo "click"

/* let botonModoAutomatico = document.getElementById("modoAutomatico");
botonModoAutomatico.addEventListener("click", () =>{
  if (botonModoAutomatico.checked) {
    if (!document.getElementById("obtenerInformacion").classList.contains("bg-blue-300")) {
      document.getElementById("obtenerInformacion").click();
    } else {
      document.getElementById("obtenerInformacion").click();
      document.getElementById("obtenerInformacion").click();
    }
  }else{
    if(document.getElementById("obtenerInformacion").classList.contains("bg-blue-300")){
      document.getElementById("obtenerInformacion").click();
    } else {
      document.getElementById("obtenerInformacion").click();
      document.getElementById("obtenerInformacion").click();
    }
  }
}) */

botonObtenerInformacion.addEventListener("click", () => {
/*   let modoAutomatico = document.getElementById("modoAutomatico").checked;
  
  if (modoAutomatico) {
    modo = "pointermove";
  } else {
    modo = "click";
  } */

  if (pointerMoveListener) {
    // Si hay un listener activo, lo eliminamos
    map.un("pointermove", pointerMoveListener);
    map.un("click", pointerMoveListener);
    pointerMoveListener = null;
  } else {
    // Si no hay listener, lo creamos
    pointerMoveListener = function (event) {
      const currentTime = Date.now();
      if (currentTime - lastExecutionTime >= 1000) {
        const feature = map.getFeaturesAtPixel(event.pixel)[0];
        let simbolo = "";
        if (feature && feature.getGeometry().getType() === "Point") {
          simbolo = feature.get("simbolo");
        }

        if (
          feature &&
          feature.getGeometry().getType() === "Point" &&
          simbolo === "simbolo"
        ) {
          console.log(feature.getGeometry().getCoordinates());
          cargaDatosASC(feature);
          lastExecutionTime = currentTime;
        }
      }
    };

    // Dependiendo del modo actual, registramos el listener correspondiente
    if (modo === "pointermove") {
      map.on("pointermove", pointerMoveListener);
    } else {
      map.on("click", pointerMoveListener);
    }
  }
});



//Cargo una capa de poligonos a partir de un geoJson 

const geoJsonObjec = "./poligonosCalles.geojson";

//Obtiene el geojson de la ruta de geoJsonObject
fetch(geoJsonObjec)
  .then((response) => response.json())
  .then((data) => {
    const geojsonFormat = new GeoJSON();
    const features = geojsonFormat.readFeatures(data);

    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: {
        "fill-color": "rgba(255, 251, 173, 0.5)",
        "stroke-color": "rgba(0, 0, 0, 0.1)",
        "stroke-width": 1,
      },
      title: "Parcelas",
    });

    // Aplicar ZIndex 0 a la capa
    vectorLayer.setZIndex(0);

    // Obtener el valor inicial del input range labels-range-input
    const labelsRangeInput = document.getElementById("labels-range-input");
    let labelsRangeInputValue = labelsRangeInput.value / 1000;

    // Actualizar la opacidad del estilo de la capa vectorial al cambiar el valor del input
    labelsRangeInput.addEventListener("input", () => {
      labelsRangeInputValue = labelsRangeInput.value / 1000;
      vectorLayer.setStyle({
        "fill-color": "rgba(255, 251, 173," + labelsRangeInputValue + ")",
        "stroke-color": "rgba(0, 0, 0, 0.1)",
        "stroke-width": 1,
      });
    });

    map.addLayer(vectorLayer);
  });

//Añado un geojson con lo limites municipales
const geoJsonMunicipal = "./limitesMunicipales.geojson";

fetch(geoJsonMunicipal)
  .then((response) => response.json())
  .then((data) => {
    const geojsonFormat = new GeoJSON();
    const features = geojsonFormat.readFeatures(data);

    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: {
        "fill-color": "rgba(255, 255, 255, 0)",
        "stroke-color": "rgba(0, 0, 0, 1)",
        "stroke-width": 2,
      },
    });

    // Aplicar ZIndex 0 a la capa
    vectorLayer.setZIndex(0);

    map.addLayer(vectorLayer);
  });

/* 

//Al enviar el formulario, quiero que se recojan las coordenadas del punto seleccionado y se muestre un console.log con ellas
const enviarFormulario = document.getElementById("enviar");

enviarFormulario.addEventListener("click", () => {
  //Añado la comprobación de que el valor de la referencia catastral se corresponda con alguno de los valores del datalist
  const referenciaCatastral = document.getElementById("valorReferenciaCatastral");
  let referenciaCatastralCorrecta = false;

  const opciones = document.getElementById("tiposReferenciaCatastral").childNodes;
  opciones.forEach((element) => {
    if (referenciaCatastral.value == element.value) {
      referenciaCatastralCorrecta = true;
    }
  });

  //Añado la comprobación de que el valor del nombre de via y numero de via se corresponda con alguno de los valores del datalist
  const nombreVia = document.getElementById("valorNombreVia");
  let nombreViaCorrecto = false;

  const opciones2 = document.getElementById("nombresVias").childNodes;
  opciones2.forEach((element) => {
    if (nombreVia.value == element.value) {
      nombreViaCorrecto = true;
    }
  });

  const numeroVia = document.getElementById("valorNumeroVias");
  let numeroViaCorrecto = false;

  const opciones3 = document.getElementById("numerosVias").childNodes;
  opciones3.forEach((element) => {
    if (numeroVia.value == element.value) {
      numeroViaCorrecto = true;
    }
  });

if (referenciaCatastralCorrecta) {
  const coordenadas = coordenadasPorReferenciaCatastral();

  //Si la promesa se resuelve correctamente, muestro las coordenadas en un console.log
  coordenadas.then((coordenadas) => {
    //Si alguna de las coordenadas empieza por 0, le quito el 0
    if (coordenadas[0].startsWith("0")) {
      coordenadas[0] = coordenadas[0].substring(1);
    }

    //Cambio las , por . para que se pueda leer como numero
    coordenadas[0] = coordenadas[0].replace(",", ".");
    coordenadas[1] = coordenadas[1].replace(",", ".");

    console.log(coordenadas);

    //Hago una peticion al servido para a partir de estas coordenadas obtener las coordenadas de toda la calle
    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      "./Request/cargaCoordenadasDeUnaViaPorCoordenadas.php"
    );
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    xhr.onload = function () {
      if (xhr.status === 200) {
        const respuesta = JSON.parse(xhr.responseText);

        //Si la respuesta es correcta, muestro las coordenadas
        if (respuesta) {
          console.log(respuesta);
          
          //Con todas las coordenadas, busco el poligono más cercano haciendo una media con todos ellos, para saber cual es el más cercano
          const geojson = "./CALLEJEpoligonsReducido.geojson";

          fetch(geojson)
            .then((response) => response.json())
            .then((data) => {
              const geojsonFormat = new GeoJSON();
              const features = geojsonFormat.readFeatures(data);

              //Busco el poligono más cercano a las coordenadas del punto seleccionado
              let poligonoMasCercano = features[0];
              let distanciaMinima = Infinity;

              features.forEach((element) => {
                const flatCoordinates = element.getGeometry().getFlatCoordinates();
                const numPoints = flatCoordinates.length / 2;

                for (let i = 0; i < numPoints; i++) {
                  const x = flatCoordinates[i * 2];
                  const y = flatCoordinates[i * 2 + 1];
                  const distancia = Math.sqrt(Math.pow(x - coordenadas[0], 2) + Math.pow(y - coordenadas[1], 2));

                  if (distancia < distanciaMinima) {
                    distanciaMinima = distancia;
                    poligonoMasCercano = element;
                  }
                }
              });

              //Creo una capa con el poligono más cercano relleno de color rojo
              const vectorSource = new VectorSource({
                features: [poligonoMasCercano],
              });

              const vectorLayer = new VectorLayer({
                source: vectorSource,
                style: {
                  "fill-color": "rgba(255, 0, 0, 0.5)",
                  "stroke-color": "rgba(0, 0, 0, 1)",
                  "stroke-width": 2,
                },
              });

              // Aplicar ZIndex 1 a la capa
              vectorLayer.setZIndex(0);

              map.addLayer(vectorLayer);
            });


        }
        //Si no, muestro el error
        else {
          alert("No se han encontrado las coordenadas");
        }
      } else if (xhr.status !== 200) {
        alert("Request failed.  Returned status of " + xhr.status);
      }
    }

    xhr.send(
      `coordenadaX=${coordenadas[0]}&coordenadaY=${coordenadas[1]}`
    );

  });
} else if (nombreViaCorrecto && numeroViaCorrecto) {
  console.log("entro en else if");
} else{
  "Fallo"
}
});
 */

//Evento que se ejecuta al hacer click en el boton enviar
const enviarFormulario = document.getElementById("enviar");

enviarFormulario.addEventListener("click", () => {
  //Si el valor de la referencia catastral coincide con alguno de los valores del datalist
  //Obtengo los valores del datalist
  const referenciaCatastralInput = document.getElementById(
    "valorReferenciaCatastral"
  );
  const referenciaCatastralDatalist = document.getElementById(
    "tiposReferenciaCatastral"
  );
  const referenciaCatastralOptions =
    referenciaCatastralDatalist.getElementsByTagName("option");

  const valorNombreVia = document.getElementById("valorNombreVia");
  const valorNombreViaDatalist = document.getElementById("nombresVias");
  const valorNombreViaOptions =
    valorNombreViaDatalist.getElementsByTagName("option");

  const valorNumeroVia = document.getElementById("valorNumeroVias");
  const valorNumeroViaDatalist = document.getElementById("numerosVias");
  const valorNumeroViaOptions =
    valorNumeroViaDatalist.getElementsByTagName("option");

  let referenciaCatastralCorrecta = false;
  let nombreViaCorrecto = false;
  let numeroViaCorrecto = false;

  for (let i = 0; i < referenciaCatastralOptions.length; i++) {
    if (
      referenciaCatastralInput.value === referenciaCatastralOptions[i].value
    ) {
      referenciaCatastralCorrecta = true;
      break;
    }
  }

  for (let i = 0; i < valorNombreViaOptions.length; i++) {
    if (valorNombreVia.value === valorNombreViaOptions[i].value) {
      nombreViaCorrecto = true;
      break;
    }
  }

  for (let i = 0; i < valorNumeroViaOptions.length; i++) {
    if (valorNumeroVia.value === valorNumeroViaOptions[i].value) {
      numeroViaCorrecto = true;
      break;
    }
  }

  if (referenciaCatastralCorrecta) {
    //Hago una petición al servidor que me devuelva el CJ a partir de las coordenadas del punto seleccionado
    const coordenadas = coordenadasPorReferenciaCatastral();

    coordenadas.then((coordenadas) => {
      //Si la promesa se resuelve correctamente, hago una peticion al servidor para que me devuelva las coordenadas de la calle seleccionada
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "./Request/enc/cargaCoordenadasDeUnaViaPorCoordenadasCifrado.php"
      );
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

      xhr.onload = function () {
        if (xhr.status === 200) {
          const respuesta = JSON.parse(xhr.responseText);

          //Guardo las coordenadas de la calle seleccionada
          const coordenadas = respuesta.map((item) => [item.X, item.Y]);

          /* console.log(coordenadas); */

          const geoJsonPoligonos = "./poligonosCalles.geojson";

          // Crear vector source y vector layer
          const vectorSource = new VectorSource();
          const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: {
              "fill-color": "rgba(255, 255, 134, 1)",
              "stroke-color": "rgba(0, 0, 0, 0)",
              "stroke-width": 1,
            },
            name: "poligonoCalle",
          });

          // Aplicar ZIndex 1 a la capa
          vectorLayer.setZIndex(0.9);

          // Borro la capa existente con el nombre "poligonoCalle"
          const existingLayer = map
            .getLayers()
            .getArray()
            .find((layer) => layer.get("name") === "poligonoCalle");
          if (existingLayer) {
            map.removeLayer(existingLayer);
          }

          map.addLayer(vectorLayer);

          fetch(geoJsonPoligonos)
            .then((response) => response.json())
            .then((data) => {
              const geojsonFormat = new GeoJSON();
              const features = geojsonFormat.readFeatures(data);

              features.forEach((element) => {
                const polygon = element.getGeometry();

                coordenadas.forEach((coordenada) => {
                  if (polygon.intersectsCoordinate(coordenada)) {
                    vectorSource.addFeature(element);
                  }
                });
              });
            });
        }
      };

      xhr.send(`coordenadaX=${coordenadas[0]}&coordenadaY=${coordenadas[1]}`);
    });
  } else if (nombreViaCorrecto && numeroViaCorrecto) {
    //Hago una peticion al servidor para que me devuelva las coordenadas del CJ de la calle seleccionada
    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      "./Request/enc/cargaCoordenadasCJPorDatosViaCifrado.php"
    );
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    xhr.onload = function () {
      if (xhr.status === 200) {
        const respuesta = JSON.parse(xhr.responseText);

        //Guardo las coordenadas de la calle seleccionada
        const coordenadas = respuesta.map((item) => [item.X, item.Y]);

        /* console.log(coordenadas); */

        const geoJsonPoligonos = "./poligonosCalles.geojson";

        // Crear vector source y vector layer
        const vectorSource = new VectorSource();
        const vectorLayer = new VectorLayer({
          source: vectorSource,
          style: {
            "fill-color": "rgba(255, 255, 134, 1)",
            "stroke-color": "rgba(0, 0, 0, 0)",
            "stroke-width": 1,
          },
          name: "poligonoCalle",
        });

        // Aplicar ZIndex 1 a la capa
        vectorLayer.setZIndex(0.9);

        // Borro la capa existente con el nombre "poligonoCalle"
        const existingLayer = map
          .getLayers()
          .getArray()
          .find((layer) => layer.get("name") === "poligonoCalle");
        if (existingLayer) {
          map.removeLayer(existingLayer);
        }

        map.addLayer(vectorLayer);

        fetch(geoJsonPoligonos)
          .then((response) => response.json())
          .then((data) => {
            const geojsonFormat = new GeoJSON();
            const features = geojsonFormat.readFeatures(data);

            features.forEach((element) => {
              const polygon = element.getGeometry();

              coordenadas.forEach((coordenada) => {
                if (polygon.intersectsCoordinate(coordenada)) {
                  vectorSource.addFeature(element);
                }
              });
            });
          });
      }
    };

    xhr.send(
      `nombreVia=${valorNombreVia.value}&numeroVia=${valorNumeroVia.value}`
    );
  } else {
  }
});

//Creo una nueva capa con los textos de los pueblos a partir de un geojson
const geoJsonPueblos = "./nucleosUrbanos.geojson";

//Controlo cuando se acaba de cargar el mapa
map.once("rendercomplete", function () {
  //Muestro todos los puntos
  fetch(geoJsonPueblos)
    .then((response) => response.json())
    .then((data) => {
      const geojsonFormat = new GeoJSON();
      const features = geojsonFormat.readFeatures(data);

      features.forEach((feature) => {
        const texto = feature.values_.Text;
        const coordenadas = feature.values_.geometry.flatCoordinates;

        //Pinto el texto en el mapa en el punto seleccionado
        const point = new Point([coordenadas[0], coordenadas[1]]);
        const textFeature = new Feature(point); // Rename the variable to 'textFeature'

        //Cuento el numero de caracteres del texto y le asigno un tamaño de letra en funcion de este
        const numeroCaracteres = texto.length;
        let tamanoLetra = 44;
        let desplazamiento = tamanoLetra * numeroCaracteres;

        //Desplazo la coordenada en funcion del numero de caracteres
        point.setCoordinates([
          coordenadas[0] + desplazamiento,
          coordenadas[1] + 21,
        ]);

        textFeature.setStyle(
          new Style({
            text: new Text({
              text: texto,
              font: "bold 16px Arial",
              fill: new Fill({
                color: "#FFF",
              }),
              stroke: new Stroke({
                color: "#000",
                width: 3,
              }),
              offsetX: 0,
              offsetY: 0,
              rotation: 0,
              textAlign: "center",
              textBaseline: "middle",
              textAlign: "center",
              textBaseline: "middle",
              rotationAnchor: [0.5, 0.5], // Set the rotation anchor to the center of the text
            }),
          })
        );

        const vectorSource = new VectorSource({
          features: [textFeature], // Use the renamed variable 'textFeature'
        });

        const vectorLayer = new VectorLayer({
          source: vectorSource,
        });

        vectorLayer.setZIndex(2);

        map.addLayer(vectorLayer);

        //Mostrar esta capa solo cuando el zoom sea menor a 6
        map.on("moveend", function () {
          const zoom = map.getView().getZoom();
          if (zoom <= 5) {
            vectorLayer.setVisible(true);
          } else {
            vectorLayer.setVisible(false);
          }
        });
      });
    });
});

// Escucho el click del boton alternarPlano
const botonAlternarPlano = document.getElementById("alternarPlano");

botonAlternarPlano.addEventListener("click", () => {
  // Disable the button
  botonAlternarPlano.disabled = true;

  // Check if the edificaciones layer has already been loaded
  const edificacionesLayer = map
    .getLayers()
    .getArray()
    .find((layer) => layer.get("name") === "edificacionesLayer");
  if (!edificacionesLayer) {
    // Load the edificaciones layer if it hasn't been loaded yet
    const geoJsonEdificaciones = "./parcelas.geojson";

    fetch(geoJsonEdificaciones)
      .then((response) => response.json())
      .then((data) => {
        const geojsonFormat = new GeoJSON();
        const features = geojsonFormat.readFeatures(data);

        const vectorSource = new VectorSource({
          features: features,
        });

        const vectorLayer = new VectorLayer({
          source: vectorSource,
          style: {
            "stroke-color": "rgba(0, 0, 0, 1)",
            "stroke-width": 1,
          },
          name: "edificacionesLayer", // Nombre de la capa de edificaciones
          visible: false,
        });

        // Aplicar ZIndex 0 a la capa
        vectorLayer.setZIndex(0);

        map.addLayer(vectorLayer);

        // Toggle the visibility of the edificaciones layer
        vectorLayer.setVisible(!vectorLayer.getVisible());

        // Enable the button
        botonAlternarPlano.disabled = false;
      });
  } else {
    // Toggle the visibility of the edificaciones layer
    edificacionesLayer.setVisible(!edificacionesLayer.getVisible());

    // Enable the button
    botonAlternarPlano.disabled = false;
  }
});

// Escucho el click del boton alternarPlano y guardo una imagen del mapa
document.getElementById("imprimir").addEventListener("click", function () {
  map.once("rendercomplete", function () {
    const mapCanvas = document.createElement("canvas");
    const size = map.getSize();
    mapCanvas.width = size[0];
    mapCanvas.height = size[1];
    const mapContext = mapCanvas.getContext("2d");
    Array.prototype.forEach.call(
      map.getViewport().querySelectorAll(".ol-layer canvas, canvas.ol-layer"),
      function (canvas) {
        if (canvas.width > 0) {
          const opacity =
            canvas.parentNode.style.opacity || canvas.style.opacity;
          mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
          let matrix;
          const transform = canvas.style.transform;
          if (transform) {
            // Get the transform parameters from the style's transform matrix
            matrix = transform
              .match(/^matrix\(([^\(]*)\)$/)[1]
              .split(",")
              .map(Number);
          } else {
            matrix = [
              parseFloat(canvas.style.width) / canvas.width,
              0,
              0,
              parseFloat(canvas.style.height) / canvas.height,
              0,
              0,
            ];
          }
          // Apply the transform to the export map context
          CanvasRenderingContext2D.prototype.setTransform.apply(
            mapContext,
            matrix
          );
          const backgroundColor = canvas.parentNode.style.backgroundColor;
          if (backgroundColor) {
            mapContext.fillStyle = backgroundColor;
            mapContext.fillRect(0, 0, canvas.width, canvas.height);
          }
          mapContext.drawImage(canvas, 0, 0);
        }
      }
    );
    mapContext.globalAlpha = 1;
    mapContext.setTransform(1, 0, 0, 1, 0, 0);
    const link = document.getElementById("image-download");
    link.href = mapCanvas.toDataURL();
    link.click();
  });
  map.renderSync();
});

//Medir distancias
const sourceMedir = new VectorSource();

const vector = new VectorLayer({
  source: sourceMedir,
  style: {
    "fill-color": "rgba(255, 255, 255, 0.2)",
    "stroke-color": "#ffcc33",
    "stroke-width": 2,
    "circle-radius": 7,
    "circle-fill-color": "#ffcc33",
  },
});

/**
 * Currently drawn feature.
 * @type {import("../src/ol/Feature.js").default}
 */
let sketch;

/**
 * The help tooltip element.
 * @type {HTMLElement}
 */
let helpTooltipElement;

/**
 * Overlay to show the help messages.
 * @type {Overlay}
 */
let helpTooltip;

/**
 * The measure tooltip element.
 * @type {HTMLElement}
 */
let measureTooltipElement;

/**
 * Overlay to show the measurement.
 * @type {Overlay}
 */
let measureTooltip;

/**
 * Message to show when the user is drawing a polygon.
 * @type {string}
 */
const continuePolygonMsg = "Haz doble click para terminar el polígono";

/**
 * Message to show when the user is drawing a line.
 * @type {string}
 */
const continueLineMsg = "Haz doble click para terminar";

/**
 * Handle pointer move.
 * @param {import("../src/ol/MapBrowserEvent").default} evt The event.
 */
const pointerMoveHandler = function (evt) {
  if (evt.dragging) {
    return;
  }
  /** @type {string} */
  let helpMsg = "Haz para empezar";

  if (sketch) {
    const geom = sketch.getGeometry();
    if (geom instanceof Polygon) {
      helpMsg = continuePolygonMsg;
    } else if (geom instanceof LineString) {
      helpMsg = continueLineMsg;
    }
  }

  helpTooltipElement.innerHTML = helpMsg;
  helpTooltip.setPosition(evt.coordinate);

  helpTooltipElement.classList.remove("hidden");
};

const typeSelect = document.getElementById("type");

let draw; // global so we can remove it later

/**
 * Format length output.
 * @param {LineString} line The line.
 * @return {string} The formatted length.
 */
const formatLength = function (line) {
  const length = getLength(line);
  let output;
  /* if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
  } else { */
  output = Math.round(length * 100) / 100 + " " + "m";
  /* } */
  return output;
};

/**
 * Format area output.
 * @param {Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
const formatArea = function (polygon) {
  const area = getArea(polygon);
  let output;
  /* if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
  } else { */
  output = Math.round(area * 100) / 100 + " " + "m<sup>2</sup>";
  /* } */
  return output;
};

const style = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.2)",
  }),
  stroke: new Stroke({
    color: "rgba(0, 0, 0, 0.5)",
    lineDash: [10, 10],
    width: 2,
  }),
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: "rgba(0, 0, 0, 0.7)",
    }),
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.2)",
    }),
  }),
});

//Manejo el onclick del boton de medir linea
const botonMedirLinea = document.getElementById("medirLinea");

botonMedirLinea.addEventListener("click", () => {
  //Añadir al boton la clase bg-blue-500
  botonMedirLinea.classList.toggle("bg-blue-300");
  botonMedirLinea.classList.toggle("p-1");
  botonMedirLinea.classList.toggle("rounded-lg");

  botonMedirLinea.querySelector("img").classList.toggle("h-10");
  botonMedirLinea.querySelector("img").classList.toggle("h-8");

  //Si el boton medirarea tiene la clase bg-blue-300, le cambio los estilos
  botonMedirArea.classList.remove("bg-blue-300");
  botonMedirArea.classList.remove("p-1");
  botonMedirArea.classList.remove("rounded-lg");

  botonMedirArea.querySelector("img").classList.remove("h-8");
  botonMedirArea.querySelector("img").classList.add("h-10");

  if (botonMedirLinea.classList.contains("bg-blue-300")) {
    //Cambio el typeSelect a line
    typeSelect.value = "length";
  } else {
    //Borro los elementos que tengan las clases ol-tooltip y ol-tooltip-static al mismo tiempo
    const elementos = document.querySelectorAll(
      ".ol-tooltip.ol-tooltip-measure"
    );
    elementos.forEach((elemento) => {
      elemento.remove();
    });

    //Cambio el typeSelect a desactivado
    typeSelect.value = "Desactivado";
  }

  //Ejecuto el onchange del typeSelect
  typeSelect.dispatchEvent(new Event("change"));
});

//Manejo el onclick del boton de medir area
const botonMedirArea = document.getElementById("medirArea");

botonMedirArea.addEventListener("click", () => {
  //Añadir al boton la clase bg-blue-500
  botonMedirArea.classList.toggle("bg-blue-300");
  botonMedirArea.classList.toggle("p-1");
  botonMedirArea.classList.toggle("rounded-lg");

  botonMedirArea.querySelector("img").classList.toggle("h-10");
  botonMedirArea.querySelector("img").classList.toggle("h-8");

  //Si el boton medirlinea tiene la clase bg-blue-300, le cambio los estilos
  botonMedirLinea.classList.remove("bg-blue-300");
  botonMedirLinea.classList.remove("p-1");
  botonMedirLinea.classList.remove("rounded-lg");

  botonMedirLinea.querySelector("img").classList.remove("h-8");
  botonMedirLinea.querySelector("img").classList.add("h-10");

  if (botonMedirArea.classList.contains("bg-blue-300")) {
    //Cambio el typeSelect a area
    typeSelect.value = "area";
  } else {
    //Borro los elementos que tengan las clases ol-tooltip y ol-tooltip-static al mismo tiempo
    const elementos = document.querySelectorAll(
      ".ol-tooltip.ol-tooltip-measure"
    );
    elementos.forEach((elemento) => {
      elemento.remove();
    });

    //Cambio el typeSelect a desactivado
    typeSelect.value = "Desactivado";
  }

  //Ejecuto el onchange del typeSelect
  typeSelect.dispatchEvent(new Event("change"));
});

function addInteraction() {
  const type = typeSelect.value == "area" ? "Polygon" : "LineString";
  draw = new Draw({
    source: sourceMedir,
    type: type,
    style: function (feature) {
      const geometryType = feature.getGeometry().getType();
      if (geometryType === type || geometryType === "Point") {
        return style;
      }
    },
  });

  if (typeSelect.value !== "Desactivado") {
    map.addInteraction(draw);
  }

  createMeasureTooltip();
  createHelpTooltip();

  let listener;
  draw.on("drawstart", function (evt) {
    // set sketch
    sketch = evt.feature;

    /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
    let tooltipCoord = evt.coordinate;

    listener = sketch.getGeometry().on("change", function (evt) {
      const geom = evt.target;
      let output;
      if (geom instanceof Polygon) {
        output = formatArea(geom);
        tooltipCoord = geom.getInteriorPoint().getCoordinates();
      } else if (geom instanceof LineString) {
        output = formatLength(geom);
        tooltipCoord = geom.getLastCoordinate();
      }
      measureTooltipElement.innerHTML = output;
      measureTooltip.setPosition(tooltipCoord);
    });
  });

  draw.on("drawend", function () {
    measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
    measureTooltip.setOffset([0, -7]);

    // Añado el sketch a la capa sketch, si no está creada la creo y si está creada añaado el sketch a la capa
    let sketchLayer = map
      .getLayers()
      .getArray()
      .find((layer) => layer.get("name") === "sketch");
    if (!sketchLayer) {
      sketchLayer = new VectorLayer({
        source: new VectorSource({
          features: [sketch],
        }),
        style: new Style({
          stroke: new Stroke({
            color: "blue",
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(0, 0, 255, 0.1)",
          }),
        }),
        name: "sketch",
      });
      sketchLayer.setZIndex(0);

      map.addLayer(sketchLayer);

      //Cambio el typeSelect a desactivado
      typeSelect.value = "Desactivado";

      //Ejecuto el onchange del typeSelect
      typeSelect.dispatchEvent(new Event("change"));

      //Vuelvo al estado inicial de los botones
      botonMedirLinea.classList.remove("bg-blue-300");
      botonMedirLinea.classList.remove("p-1");
      botonMedirLinea.classList.remove("rounded-lg");

      botonMedirLinea.querySelector("img").classList.remove("h-8");
      botonMedirLinea.querySelector("img").classList.add("h-10");

      botonMedirArea.classList.remove("bg-blue-300");
      botonMedirArea.classList.remove("p-1");
      botonMedirArea.classList.remove("rounded-lg");

      botonMedirArea.querySelector("img").classList.remove("h-8");
      botonMedirArea.querySelector("img").classList.add("h-10");
    } else {
      sketchLayer.getSource().addFeature(sketch);

      //Cambio el typeSelect a desactivado
      typeSelect.value = "Desactivado";

      //Ejecuto el onchange del typeSelect
      typeSelect.dispatchEvent(new Event("change"));

      //Vuelvo al estado inicial de los botones
      botonMedirLinea.classList.remove("bg-blue-300");
      botonMedirLinea.classList.remove("p-1");
      botonMedirLinea.classList.remove("rounded-lg");

      botonMedirLinea.querySelector("img").classList.remove("h-8");
      botonMedirLinea.querySelector("img").classList.add("h-10");

      botonMedirArea.classList.remove("bg-blue-300");
      botonMedirArea.classList.remove("p-1");
      botonMedirArea.classList.remove("rounded-lg");

      botonMedirArea.querySelector("img").classList.remove("h-8");
      botonMedirArea.querySelector("img").classList.add("h-10");
    }

    // unset sketch
    sketch = null;
    // unset tooltip so that a new one can be created
    measureTooltipElement = null;
    createMeasureTooltip();
    unByKey(listener);
  });
}

/**
 * Creates a new help tooltip
 */
function createHelpTooltip() {
  if (helpTooltipElement) {
    helpTooltipElement.parentNode.removeChild(helpTooltipElement);
  }
  helpTooltipElement = document.createElement("div");
  helpTooltipElement.className = "ol-tooltip hidden";
  helpTooltip = new Overlay({
    element: helpTooltipElement,
    offset: [15, 0],
    positioning: "center-left",
  });
  map.addOverlay(helpTooltip);
}

/**
 * Creates a new measure tooltip
 */
function createMeasureTooltip() {
  /* if (measureTooltipElement !== undefined) {
    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
  }  */
  measureTooltipElement = document.createElement("div");
  measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
  measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: "bottom-center",
    stopEvent: false,
    insertFirst: false,
  });

  map.addOverlay(measureTooltip);
}

//Borro la capa sketch al hacer click en el boton borrar
let borrar = document.getElementById("borrar");

borrar.addEventListener("click", () => {
  //Borro la capa sketch
  const sketchLayer = map
    .getLayers()
    .getArray()
    .find((layer) => layer.get("name") === "sketch");
  if (sketchLayer) {
    map.removeLayer(sketchLayer);

    //Quito los divs de las medidas que tengan la clase ol-tooltip-static
    const tooltips = document.querySelectorAll(".ol-tooltip-static");
    tooltips.forEach((tooltip) => tooltip.parentNode.removeChild(tooltip));
  }
});

/**
 * Let user change the geometry type.
 */
typeSelect.onchange = function () {
  map.removeInteraction(draw);
  if (typeSelect.value !== "Desactivado") {
    map.getViewport().addEventListener("mouseout", function () {
      helpTooltipElement.classList.add("hidden");
    });

    map.on("pointermove", pointerMoveHandler);

    addInteraction();
  } else {
    //Quito la ayuda del raton
    map.removeOverlay(helpTooltip);
  }
};

if (typeSelect.value !== "Desactivado") {
  addInteraction();
}


let ocultaNumerosPoliciaContainer = document.getElementById("ocultaNumerosPoliciaContainer");
let ocultaNumerosPolicia = document.getElementById("ocultaNumerosPolicia");

// Manejo del botón para esconder los textos de los números de policía
ocultaNumerosPolicia.addEventListener("click", () => {
  // Añadir al botón la clase bg-blue-300
  ocultaNumerosPoliciaContainer.classList.toggle("bg-blue-300");
  ocultaNumerosPoliciaContainer.classList.toggle("p-1");
  ocultaNumerosPoliciaContainer.classList.toggle("rounded-lg");

  ocultaNumerosPoliciaContainer.querySelector("img").classList.toggle("h-10");
  ocultaNumerosPoliciaContainer.querySelector("img").classList.toggle("h-8");

  // Alternar visibilidad del texto
  let capas = map.getLayers().getArray();
  let capaNumerosPolicia = capas.find(capa => capa.get('name') === 'layerNumerosPolicia');

  if (capaNumerosPolicia) {
    let features = capaNumerosPolicia.getSource().getFeatures();
    features.forEach((feature) => {
      let style = feature.getStyle();
      if (style && style.getText()) {
        let text = style.getText();
        if (text.getText()) {
          feature.set('originalText', text.getText());  // Guardar el texto original
          text.setText("");  // Ocultar el texto
        } else {
          text.setText(feature.get('originalText'));  // Restaurar el texto original
        }
      }
    });
    // Redibujar la capa para aplicar los cambios
    capaNumerosPolicia.getSource().changed();
  }
});

























// Logica para el map2 (plano)
let ultimaReferenciaCatastralEnviada = "";

//Inicializo la promesa de carga de estilos
let estilosPromise = "";
let estilosEstablecidos = false;

const botonEnviar = document.getElementById("enviar");

//Muestro el plano al hacer click en el boton de id alternarOrtofotoPlano
const botonAlternarOrtofotoPlano = document.getElementById(
  "alternarOrtofotoPlano"
);

botonEnviar.addEventListener("click", () => {
  const referenciaCatastral = document.getElementById(
    "valorReferenciaCatastral"
  ).value;
  const referenciaCatastralDatalist = document.getElementById(
    "tiposReferenciaCatastral"
  );
  const valorNombreVia = document.getElementById("valorNombreVia");
  const valorNombreViaDatalist = document.getElementById("nombresVias");
  const valorNumeroVia = document.getElementById("valorNumeroVias");
  const valorNumeroViaDatalist = document.getElementById("numerosVias");


  //Compruebo si la referencia catastral se corresponde con alguno de los valores del datalist
  let referenciaCatastralCorrecta = false;
  for (let i = 0; i < referenciaCatastralDatalist.options.length; i++) {
    if (referenciaCatastralDatalist.options[i].value === referenciaCatastral) {
      referenciaCatastralCorrecta = true;
      break;
    }
  }

  let nombreViaCorrecto = false;
  let numeroViaCorrecto = false;
  if (!referenciaCatastralCorrecta) {
    //Compruebo si el nombre de la via y el numero de la via se corresponden con alguno de los valores del datalist
    for (let i = 0; i < valorNombreViaDatalist.options.length; i++) {
      if (valorNombreVia.value === valorNombreViaDatalist.options[i].value) {
        nombreViaCorrecto = true;
        break;
      }
    }

    for (let i = 0; i < valorNumeroViaDatalist.options.length; i++) {
      if (valorNumeroVia.value === valorNumeroViaDatalist.options[i].value) {
        numeroViaCorrecto = true;
        break;
      }
    }
  }


    if (referenciaCatastralCorrecta) {
      ultimaReferenciaCatastralEnviada = referenciaCatastral;
    } else if (nombreViaCorrecto && numeroViaCorrecto) {
      //Hago una peticion al servidor para obtener la referencia catastral que se corresponde con el nombre de la via y el numero de la via
      let promesaReferenciaCatastral = cargaReferenciaCatastralPorVia();
      
      //Espero a que se resuelva la promesa y hago un console.log con el valor de la referencia catastral
      promesaReferenciaCatastral.then((referenciaCatastral) => {
        ultimaReferenciaCatastralEnviada = referenciaCatastral;
      });
    } else {
      ultimaReferenciaCatastralEnviada = ultimaReferenciaCatastralEnviada;
    }
});

botonAlternarOrtofotoPlano.addEventListener("click", () => {
  //Hago toggle de la clase hidden para map y map2
  const map = document.getElementById("map");
  const map2id = document.getElementById("map2");

  //Hago toggle de la clase hidden para los botones
  const botonAlternarPlanoContainer = document.getElementById(
    "alternarPlanoContainer"
  );
  const botonObtenerInformacionContainer = document.getElementById(
    "obtenerInformacionContainer"
  );
  const botonImprimirContainer = document.getElementById("imprimirContainer");
  const botonAmpliarContainer = document.getElementById("ampliarContainer");
  const botonMedirLineaContainer = document.getElementById(
    "medirLineaContainer"
  );
  const botonMedirAreaContainer = document.getElementById("medirAreaContainer");
  const botonBorrarContainer = document.getElementById("borrarContainer");
  const cambiarOpacidadCallesContainer = document.getElementById(
    "cambiarOpacidadCallesContainer"
  );
  /*   const inputAmpliarTexto = document.getElementById("ampliarTexto"); */
  const cambiarCapaContainer = document.getElementById("cambiarCapaContainer");
  const addDibujoContainer = document.getElementById("addDibujoContainer");
  const ocultaNumerosPoliciaContainer = document.getElementById("ocultaNumerosPoliciaContainer");

  map.classList.toggle("hidden");
  map2id.classList.toggle("hidden");
  botonAlternarPlanoContainer.classList.toggle("hidden");
  botonObtenerInformacionContainer.classList.toggle("hidden");
  botonImprimirContainer.classList.toggle("hidden");
  botonAmpliarContainer.classList.toggle("hidden");
  botonMedirLineaContainer.classList.toggle("hidden");
  botonMedirAreaContainer.classList.toggle("hidden");
  botonBorrarContainer.classList.toggle("hidden");
  cambiarOpacidadCallesContainer.classList.toggle("hidden");
  /*   inputAmpliarTexto.classList.toggle("hidden"); */
  cambiarCapaContainer.classList.toggle("hidden");
  addDibujoContainer.classList.toggle("hidden");
  ocultaNumerosPoliciaContainer.classList.toggle("hidden");

  if(!estilosEstablecidos){
    //Hago un fetch de estilosCapas.json y cuando se resuelva la promesa establezco los estilos y dibujo el geojson
    fetch("./estilosCapas.json")
    .then((response) => response.json())
    .then((data) => {
      estilosPromise = data;
      estilosEstablecidos = true;
      dibujarGeoJson();
    });
  }else{
    dibujarGeoJson();
  }

  //Si map2 tiene la clase hidden, vacio el div map2 y libero la memoria
  /* if (map2id.classList.contains("hidden")) {
    //Libero los recursos del map2 y todas sus capas
    map2id.innerHTML = "";
  } */
});

//Al hacer click en el boton enviar, compruebo si el mapa2 tiene la clase hidden y si no la si la referencia catastral es valida muestro el nuevo dibujo
document.getElementById("enviar").addEventListener("click", (event) => {
  
  if (!document.getElementById("map2").classList.contains("hidden")) {
    const referenciaCatastral = document.getElementById(
      "valorReferenciaCatastral"
    ).value;
    const referenciaCatastralDatalist = document.getElementById(
      "tiposReferenciaCatastral"
    );
    const valorNombreVia = document.getElementById("valorNombreVia");
    const valorNombreViaDatalist = document.getElementById("nombresVias");
    const valorNumeroVia = document.getElementById("valorNumeroVias");
    const valorNumeroViaDatalist = document.getElementById("numerosVias");
  
  
    //Compruebo si la referencia catastral se corresponde con alguno de los valores del datalist
    let referenciaCatastralCorrecta = false;
    for (let i = 0; i < referenciaCatastralDatalist.options.length; i++) {
      if (referenciaCatastralDatalist.options[i].value === referenciaCatastral) {
        referenciaCatastralCorrecta = true;
        break;
      }
    }
  
    let nombreViaCorrecto = false;
    let numeroViaCorrecto = false;
    if (!referenciaCatastralCorrecta) {
      //Compruebo si el nombre de la via y el numero de la via se corresponden con alguno de los valores del datalist
      for (let i = 0; i < valorNombreViaDatalist.options.length; i++) {
        if (valorNombreVia.value === valorNombreViaDatalist.options[i].value) {
          nombreViaCorrecto = true;
          break;
        }
      }
  
      for (let i = 0; i < valorNumeroViaDatalist.options.length; i++) {
        if (valorNumeroVia.value === valorNumeroViaDatalist.options[i].value) {
          numeroViaCorrecto = true;
          break;
        }
      }
    }
  
  
      if (referenciaCatastralCorrecta) {
        ultimaReferenciaCatastralEnviada = referenciaCatastral;
        dibujarGeoJson();
      } else if (nombreViaCorrecto && numeroViaCorrecto) {
        //Hago una peticion al servidor para obtener la referencia catastral que se corresponde con el nombre de la via y el numero de la via
        let promesaReferenciaCatastral = cargaReferenciaCatastralPorVia();
        
        //Espero a que se resuelva la promesa y hago un console.log con el valor de la referencia catastral
        promesaReferenciaCatastral.then((referenciaCatastral) => {
          ultimaReferenciaCatastralEnviada = referenciaCatastral;
          dibujarGeoJson();
        });
      } else {
        ultimaReferenciaCatastralEnviada = ultimaReferenciaCatastralEnviada;
        dibujarGeoJson();
      }

      
  }
});






//Espero a que se cargue el map, cuando termine de cargar, hago un console.log
  const map2id = document.getElementById("map2");

  // Crea la fuente de datos vectoriales vacía
  const vectorSource = new VectorSource();

  // Crea la capa vectorial con la fuente de datos
  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: new Style({
      stroke: new Stroke({
        color: "black",
      }),
    }),
  });

  // Crea el mapa
  const map2 = new Map({
    interactions: defaultInteractions({ doubleClickZoom: false }).extend([
      new DragRotate(),
    ]),
    target: "map2",
    layers: [
      // Capa base blanca
      new TileLayer({
        source: new XYZ({}),
      }),
      // Capa vectorial con el GeoJSON
      vectorLayer,
    ],
  });

  // Añado la escala
  map2.addControl(new ScaleLine());

  const dragRotate2 = new DragRotate({
    condition: shiftKeyOnly,
  });

  map2.addInteraction(dragRotate2);

  // Desactiva la interacción de zoom por defecto cuando se mantiene pulsada la tecla shift
  map2.getInteractions().forEach(function (interaction) {
    if (interaction instanceof DragZoom) {
      interaction.setActive(false);
    }
  });

  //Hago un console.log cuando se empieza a cargar el mapa
  map2.once("precompose", function (event) {
    //Obtengo el title dee los botones de zoom y rotacion
    const zoomIn = document.getElementsByClassName("ol-zoom-in")[0];
    const zoomOut = document.getElementsByClassName("ol-zoom-out")[0];
    const rotate = document.getElementsByClassName("ol-rotate-reset")[0];

    //Cambio el title de los botones de zoom y rotacion
    zoomIn.title = "Ampliar";
    zoomOut.title = "Reducir";
    rotate.title = "Rotar";
  });





let manejadorCambioCapas = false;
//Funcion para dibujar el GeoJson
function dibujarGeoJson() {
  const map = document.getElementById("map");

  // Limpio todos los elementos del map2 pero sin borrar el map
  map2
        .getLayers()
        .getArray()
        .forEach((layer) => {
          layer.getSource().clear();
        });


  let geojsonsYAsc = geojsonPorReferenciaCatastral(ultimaReferenciaCatastralEnviada);
  geojsonsYAsc.then((geojson) => {
    let geoJsonData = JSON.parse(geojson[0].geojson);
    let ascData = geojson[0].asci;
    /* console.log(geoJsonData);
    console.log(geojson[0].asci); */

      // Procesa la información del archivo ASC
      let lineas = ascData.split("\n");
      let tipoVia = lineas[5];
      let nombreVia = lineas[6];
      let numeroVia = lineas[7].replace(/^0+/, "");
      let letraVia = lineas[8];
      let nombreViaCompleto = `${tipoVia.trim()}, ${nombreVia} ${numeroVia} ${
        letraVia === " " ? "" : letraVia
      }`;
      console.log(nombreViaCompleto);

      // Parsea el GeoJSON y añade las características a la fuente de datos vectoriales
      const format = new GeoJSON();
      geoJsonData.features.forEach((feature) => {
        if (feature.properties.Layer.startsWith("PG-")) {
          const featureToAdd = format.readFeature(feature);

          // Obtén los dos primeros y últimos caracteres del nombre de la capa
          let dosPrimerosCaracteres = featureToAdd.values_.Layer.substring(
        0,
        2
          );
          let dosUltimosCaracteres = featureToAdd.values_.Layer.substring(
        featureToAdd.values_.Layer.length - 2
          );

          // Busca el estilo correspondiente en el archivo JSON
          let estilo = estilosPromise.estilos.find((element) => {
        let capa = element.capa;
        return (
          capa.substring(0, 2) === dosPrimerosCaracteres &&
          capa.substring(capa.length - 2) === dosUltimosCaracteres
        );
          });

          // Aplica el estilo correspondiente
          if (estilo) {
        featureToAdd.setStyle(
          new Style({
            stroke: new Stroke({
          color: estilo.color,
          width: estilo.lineWidth,
          lineDash: [
            parseInt(estilo.lineDash),
            parseInt(estilo.lineSeparation),
          ],
            }),
          })
        );
          } else {
        featureToAdd.setStyle(
          new Style({
            stroke: new Stroke({
          color: "black",
            }),
          })
        );
          }

          vectorSource.addFeature(featureToAdd);
        }
      });

      // Comprueba si existe alguna capa PG-LF
      let capaPG_LF = geoJsonData.features.find(
        (feature) => feature.properties.Layer === "PG-LF"
      )
        ? true
        : false;

      if (capaPG_LF) {
        let angle = 0;
        geoJsonData.features.forEach((feature) => {
          // Para la línea que se encuentra en la capa PG-LF, obtén el ángulo que forma con la horizontal
          if (feature.properties.Layer === "PG-LF") {
            const geometry = feature.geometry;
            const coordinates = geometry.coordinates;
            angle = Math.atan2(
              coordinates[1][1] - coordinates[0][1],
              coordinates[1][0] - coordinates[0][0]
            );

            // Ajusta el ángulo si es necesario
            if (Math.abs(angle) > 0 && Math.abs(angle) <= Math.PI / 2) {
              angle = angle - Math.PI / 2;
            } else if (
              Math.abs(angle) > Math.PI / 2 &&
              Math.abs(angle) <= Math.PI
            ) {
              angle = angle - Math.PI / 2;
            } else if (
              Math.abs(angle) > Math.PI &&
              Math.abs(angle) <= (3 * Math.PI) / 2
            ) {
              angle = angle - Math.PI / 2;
            } else if (
              Math.abs(angle) > (3 * Math.PI) / 2 &&
              Math.abs(angle) <= 2 * Math.PI
            ) {
              angle = angle - Math.PI / 2;
            } else {
              angle = angle;
            }
            console.log(angle);
            // Obtiene la posición central de la capa PG-LF
            const centroX =
              (geometry.coordinates[0][0] + geometry.coordinates[1][0]) / 2;
            const centroY =
              (geometry.coordinates[0][1] + geometry.coordinates[1][1]) / 2;

            // Posiciona el texto con el nombre de la vía en el centro de la layer PG-LF
            const point = new Point([centroX, centroY]);
            const textFeature = new Feature(point);
            textFeature.setStyle(
              new Style({
                text: new Text({
                  text: nombreViaCompleto,
                  font: "bold 14px Arial",
                  fill: new Fill({
                    color: "blue",
                  }),
                }),
              })
            );

            const vectorSourceTextoCalle = new VectorSource({
              features: [textFeature],
            });

            const vectorLayerTextoCalle = new VectorLayer({
              source: vectorSourceTextoCalle,
            });

            map2.addLayer(vectorLayerTextoCalle);

            // Gira el mapa de forma que quede a 90 grados del eje x teniendo en cuenta el ángulo
            map2.getView().setRotation(angle);
          }
        });
      } else {
        // Añade al GeoJSON el nombre de la vía
        const texto = nombreViaCompleto;

        // Obtiene los límites máximos y mínimos del GeoJSON
        const extent = vectorSource.getExtent();

        // Guarda en una variable el punto central del eje x del GeoJSON
        const centroX = (extent[0] + extent[2]) / 2;

        // Guarda en una variable el punto más bajo del eje y del GeoJSON
        const puntoMasBajo = extent[1];

        // Pinta el texto en el mapa en el punto seleccionado
        const point = new Point([centroX, puntoMasBajo]);

        const textFeature = new Feature(point);

        textFeature.setStyle(
          new Style({
            text: new Text({
              text: texto,
              font: "bold 14px Arial",
              fill: new Fill({
                color: "blue",
              }),
            }),
          })
        );

        const vectorSourceTextoCalle = new VectorSource({
          features: [textFeature],
        });

        const vectorLayerTextoCalle = new VectorLayer({
          source: vectorSourceTextoCalle,
        });
        map2.getView().setRotation(0);
        map2.addLayer(vectorLayerTextoCalle);
      }

      // Ajusta la vista del mapa2 a la extensión del GeoJSON
      const extent = vectorSource.getExtent();
      const padding = [50, 50, 50, 50];
      map2.getView().fit(extent, { padding }); 

      // Guarda en un array los textos de cada feature junto con sus coordenadas y rotación
      const textos = geoJsonData.features
        // Muestra solo los textos que estén en la capa del filtro
        .filter((feature) => feature.properties.Layer.startsWith("PG-"))
        .map((feature) => {
          return {
            texto: feature.properties.Text,
            coordenadas: feature.geometry.coordinates,
            rotacion: feature.properties.rotation || 0,
            scale: feature.properties.scale || 1,
            capa: feature.properties.Layer,
          };
        });

      // Crear una capa vectorial para los textos
      const textoVectorLayer = new VectorLayer({
        source: new VectorSource(),
        name: "textoLayer", // Asignar un nombre a la capa
      });

      // Agregar la capa al mapa
      map2.addLayer(textoVectorLayer);

      // Calcula el ángulo de giro del mapa
      const rotationMap = map2.getView().getRotation();

      // Capa de textos con los estilos del JSON aplicado según su nombre de capa
      const textoLayer = new VectorLayer({
        source: new VectorSource(),
        style: function (feature, resolution) {
          // Calcula el tamaño de la fuente en función de la resolución del mapa
          const fontSize = 0.8 / resolution; // 0.8 es el tamaño de fuente base
          const rotation = feature.get("rotacion");
          const scale = feature.get("scale");
          const layer = feature.get("Layer");

          /* const length =
            feature.get("texto") !== undefined
              ? feature.get("texto").length
              : 0;

          // Calcula el tamaño que ocupa cada letra
          const offsetX = (length * fontSize * scale) / 4; */
          return new Style({
            text: new Text({
              font: fontSize + "px Arial",
              text: feature.get("texto"),
              fill: new Fill({ color: feature.get("color") }), // Recupera el color del texto desde el atributo "color"

              // Aplica la rotación del mapa
              rotation:
                rotation === 0
                  ? (360 - rotation) * (Math.PI / 180) - rotationMap
                  : (360 - rotation) * (Math.PI / 180),
              /* offsetX: offsetX, */
              rotateWithView: true,
              offsetY: /-AS$/.test(layer) ? -fontSize : 0,
            }),
          });
        },
      });

      // Añade los textos al textoLayer
      textos.forEach((texto) => {
        let estilo = estilosPromise.estilos.find(
          (element) => element.capa === texto.capa
        );

        // Agrega el color del texto al objeto de texto
        texto.color = estilo ? estilo.color : "black";

        const feature = new Feature({
          geometry: new Point(texto.coordenadas),
        });
        feature.set("texto", texto.texto);
        feature.set("rotacion", texto.rotacion);
        feature.set("scale", texto.scale);
        feature.set("color", texto.color); // Establece el color del texto como un atributo
        feature.set("Layer", texto.capa); // Establece el nombre de la capa como un atributo

        textoLayer.getSource().addFeature(feature);
      });

      // Asigna un nombre a la capa de texto
      textoLayer.set("name", "textoLayer");

      // Añade la capa de texto al mapa
      map2.addLayer(textoLayer);

      // Añade al id cambiarCapa opciones con los nombres de las capas del GeoJSON
      const cambiarCapa = document.getElementById("cambiarCapa");

      // Vacía el select cambiarCapa
      cambiarCapa.innerHTML = "";

      // Recorre las features del GeoJSON y guarda en un array los nombres únicos de las capas
      let capas = ["Planta General"]; // Por defecto, añade la capa de Planta General
      obtenerNombresCapasASC(
       ultimaReferenciaCatastralEnviada
      ).then((data) => {
        data.forEach((nombrePlanta) => {
          capas.push(nombrePlanta);
        });

        // Añade al select cambiarCapa las opciones de las capas del GeoJSON
        capas.forEach((capa) => {
          // Comprueba si la opción ya existe en el select
          if (!cambiarCapa.querySelector(`option[value="${capa}"]`)) {
            let option = document.createElement("option");
            option.value = capa;
            option.text = capa;
            cambiarCapa.appendChild(option);
          }
        });
      });
    })
    .catch((error) => {
      /* console.error("Error al cargar el GeoJSON:", error); */
      let alerta = `
    <div class="flex items-center p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50" role="alert">
      <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span class="sr-only">Info</span>
      <div>
        Esta referencia catastral no tiene ningún dibujo asociado
      </div>
    </div>
    `;

      //Muestro la alerta en el centro del eje x y a 68px del eje y
      let divAlerta = document.createElement("div");
      divAlerta.innerHTML = alerta;
      divAlerta.style.position = "absolute";
      divAlerta.style.left = "50%";
      divAlerta.style.transform = "translateX(-50%)";
      divAlerta.style.top = "68px";
      document.body.appendChild(divAlerta);

      setTimeout(() => {
        divAlerta.remove();
      }, 5000);
    });

  //Actualizo el div info con las coordenadas del raton
  map2.on("pointermove", function (event) {
    const info = document.getElementById("info");
    info.innerHTML = `<table class="float-right">
  <tbody>
    <tr><td><b>X: </b>${event.coordinate[0].toFixed(
      2
    )}&nbsp&nbsp&nbsp<b>Y: </b>${event.coordinate[1].toFixed(2)}</td></tr>
  </tbody>
  </table>`;
    //Cambio los estilos de info para que el contenido se muestre alineado a la derecha
    info.classList.add("text-right");
    info.classList.add("pr-1");
  });


  if(!manejadorCambioCapas){
  //Manejo el onchange del select cambiarCapa
  cambiarCapa.addEventListener("change", (event) => {
    //Obtengo el valor del select
    let capaSeleccionada = event.target.value;

    //Obtengo el angulo inicial del mapa
    const anguloInicial = map2.getView().getRotation();


    //Obtengo el valor seleccionado y lo muestro en un console.log
    console.log(capaSeleccionada);
    if (capaSeleccionada === "Planta General") {
      // Borro el contenido que tenga en el mapa
      map2
        .getLayers()
        .getArray()
        .forEach((layer) => {
          layer.getSource().clear();
        });

        
        
      // Realizo las solicitudes de manera simultánea utilizando Promise.all
      Promise.all([
        /* fetch(
          `./planos/${ultimaReferenciaCatastralEnviada}.geojson`
        ).then((response) => response.json()),
        fetch(
          `./asc/${ultimaReferenciaCatastralEnviada}.asc`
        )
          .then((response) => {
        // Decodifica el texto manualmente con la codificación Windows-1252
        return response.arrayBuffer();
          })
          .then((buffer) => {
        // Decodifica el buffer como un string utilizando la codificación Windows-1252
        const decoder = new TextDecoder("windows-1252");
        return decoder.decode(buffer);
          }), */
          geojsonPorReferenciaCatastral(ultimaReferenciaCatastralEnviada).then((data) => {

        return [JSON.parse(data[0].geojson), data[0].asci];
          }),
        estilosPromise,
      ])
        .then(([data, estilosData]) => {
          /* console.log(geoJsonData);
          console.log(ascData) */;
          let geoJsonData = data[0];
          let ascData = data[1];

          // Procesar el ASC Data
          let lineas = ascData.split("\n");
          let tipoVia = lineas[5];
          let nombreVia = lineas[6];
          let numeroVia = lineas[7].replace(/^0+/, "");
          let letraVia = lineas[8];
          let nombreViaCompleto = `${tipoVia.trim()}, ${nombreVia} ${numeroVia} ${
            letraVia === " " ? "" : letraVia
          }`;

          console.log(nombreViaCompleto);

          // Parsear el GeoJSON y añadir las características a la fuente de datos vectoriales
          const format = new GeoJSON();
          geoJsonData.features.forEach((feature) => {
            if (feature.properties.Layer.startsWith("PG-")) {
              const featureToAdd = format.readFeature(feature);
              // Obtener el nombre de la capa
              let nombreCapa = featureToAdd.values_.Layer;

              // Obtener los dos primeros caracteres
              let dosPrimerosCaracteres = nombreCapa.substring(0, 2);

              // Obtener los dos últimos caracteres
              let dosUltimosCaracteres = nombreCapa.substring(
                nombreCapa.length - 2
              );

              // Buscar en el json si algún elemento tiene como dos primeros caracteres "dosPrimerosCaracteres" y como dos últimos "dosUltimosCaracteres"
              let estilo = estilosData.estilos.find((element) => {
                let capa = element.capa;
                return (
                  capa.substring(0, 2) === dosPrimerosCaracteres &&
                  capa.substring(capa.length - 2) === dosUltimosCaracteres
                );
              });

              // Asignar el estilo adecuado
              if (estilo) {
                featureToAdd.setStyle(
                  new Style({
                    stroke: new Stroke({
                      color: estilo.color,
                      width: estilo.lineWidth,
                      lineDash: [
                        parseInt(estilo.lineDash),
                        parseInt(estilo.lineSeparation),
                      ],
                    }),
                  })
                );
              } else {
                featureToAdd.setStyle(
                  new Style({
                    stroke: new Stroke({
                      color: "black",
                    }),
                  })
                );
              }

              vectorSource.addFeature(featureToAdd);
            }
          });

          // Comprobar si existe alguna capa PG-LF
          let capaPG_LF = geoJsonData.features.find(
            (feature) => feature.properties.Layer === "PG-LF"
          )
            ? true
            : false;

          if (capaPG_LF) {
            let angle = 0;
            geoJsonData.features.forEach((feature) => {
              // Para la línea que se encuentra en la capa PG-LF, obtengo el ángulo que forma con la horizontal y lo guardo en una variable
              if (feature.properties.Layer === "PG-LF") {
                const geometry = feature.geometry;
                const coordinates = geometry.coordinates;
                angle = Math.atan2(
                  coordinates[1][1] - coordinates[0][1],
                  coordinates[1][0] - coordinates[0][0]
                );

                // Ajustar el ángulo si es necesario
                if (Math.abs(angle) > 0 && Math.abs(angle) <= Math.PI / 2) {
                  angle = angle - Math.PI / 2;
                } else if (
                  Math.abs(angle) > Math.PI / 2 &&
                  Math.abs(angle) <= Math.PI
                ) {
                  angle = angle - Math.PI / 2;
                } else if (
                  Math.abs(angle) > Math.PI &&
                  Math.abs(angle) <= (3 * Math.PI) / 2
                ) {
                  angle = angle - Math.PI / 2;
                } else if (
                  Math.abs(angle) > (3 * Math.PI) / 2 &&
                  Math.abs(angle) <= 2 * Math.PI
                ) {
                  angle = angle - Math.PI / 2;
                } else {
                  angle = angle;
                }

                // Obtener la posición central de la capa PG-LF
                const centroX =
                  (geometry.coordinates[0][0] + geometry.coordinates[1][0]) / 2;
                const centroY =
                  (geometry.coordinates[0][1] + geometry.coordinates[1][1]) / 2;

                // Posicionar el texto con el nombre de la vía en el centro de la layer PG-LF
                const point = new Point([centroX, centroY]);
                const textFeature = new Feature(point);
                textFeature.setStyle(
                  new Style({
                    text: new Text({
                      text: nombreViaCompleto,
                      font: "bold 14px Arial",
                      fill: new Fill({
                        color: "blue",
                      }),
                    }),
                  })
                );

                const vectorSourceTextoCalle = new VectorSource({
                  features: [textFeature],
                });

                const vectorLayerTextoCalle = new VectorLayer({
                  source: vectorSourceTextoCalle,
                });

                map2.addLayer(vectorLayerTextoCalle);

                // Girar el mapa de forma que quede a 90 grados del eje x teniendo en cuenta el ángulo
                map2.getView().setRotation(angle);
              }
            });
          } else {
            // Añadir al GeoJSON el nombre de la vía
            const texto = nombreViaCompleto;

            // Obtener los límites máximos y mínimos del GeoJSON
            const extent = vectorSource.getExtent();

            // Guardar en una variable el punto central del eje x del GeoJSON
            const centroX = (extent[0] + extent[2]) / 2;

            // Guardar en una variable el punto más bajo del eje y del GeoJSON
            const puntoMasBajo = extent[1];

            // Pintar el texto en el mapa en el punto seleccionado
            const point = new Point([centroX, puntoMasBajo]);

            const textFeature = new Feature(point);

            textFeature.setStyle(
              new Style({
                text: new Text({
                  text: texto,
                  font: "bold 14px Arial",
                  fill: new Fill({
                    color: "blue",
                  }),
                }),
              })
            );

            const vectorSourceTextoCalle = new VectorSource({
              features: [textFeature],
            });

            const vectorLayerTextoCalle = new VectorLayer({
              source: vectorSourceTextoCalle,
            });

            map2.addLayer(vectorLayerTextoCalle);
          }

          // Ajustar la vista del mapa a la extensión del GeoJSON
          const extent = vectorSource.getExtent();
          const padding = [50, 50, 50, 50];
          map2.getView().fit(extent, { padding });

          // Guardar en un array los textos de cada feature junto con sus coordenadas y rotación
          const textos = geoJsonData.features
            // Mostrar solo los textos que estén en la capa del filter
            .filter((feature) => feature.properties.Layer.startsWith("PG-"))
            .map((feature) => {
              return {
                texto: feature.properties.Text,
                coordenadas: feature.geometry.coordinates,
                rotacion: feature.properties.rotation || 0,
                scale: feature.properties.scale || 1,
                capa: feature.properties.Layer,
              };
            });

          // Crear una capa vectorial para los textos
          const textoVectorLayer = new VectorLayer({
            source: new VectorSource(),
            name: "textoLayer", // Asignar un nombre a la capa
          });

          // Agregar la capa al mapa
          map2.addLayer(textoVectorLayer);

          // Calculo el ángulo de giro del mapa
          const rotationMap = map2.getView().getRotation();

          // Capa de textos con los estilos del json aplicado segun su nombre de capa
          const textoLayer = new VectorLayer({
            source: new VectorSource(),
            style: function (feature, resolution) {
              // Calculo el tamaño de la fuente en función de la resolución del mapa
              const fontSize = 0.8 / resolution; // 0.8 es el tamaño de fuente base
              const rotation = feature.get("rotacion");
              const scale = feature.get("scale");
              const layer = feature.get("Layer");

              /* const length =
                feature.get("texto") !== undefined
                  ? feature.get("texto").length
                  : 0;

              // Calculo el tamaño que ocupa cada letra
              const offsetX = (length * fontSize * scale) / 4; */
              return new Style({
                text: new Text({
                  font: fontSize + "px Arial",
                  text: feature.get("texto"),
                  fill: new Fill({ color: feature.get("color") }), // Recupero el color del texto desde el atributo "color"

                  // Aplico la rotación del mapa
                  rotation:
                    rotation === 0
                      ? (360 - rotation) * (Math.PI / 180) - rotationMap
                      : (360 - rotation) * (Math.PI / 180),
                  /* offsetX: offsetX, */
                  rotateWithView: true,
                  offsetY: /-AS$/.test(layer) ? -fontSize : 0,
                }),
              });
            },
          });

          // Añado los textos al textoLayer
          textos.forEach((texto) => {
            let estilo = estilosData.estilos.find(
              (element) => element.capa === texto.capa
            );

            // Agrego el color del texto al objeto de texto
            texto.color = estilo ? estilo.color : "black";

            const feature = new Feature({
              geometry: new Point(texto.coordenadas),
            });
            feature.set("texto", texto.texto);
            feature.set("rotacion", texto.rotacion);
            feature.set("scale", texto.scale);
            feature.set("color", texto.color); // Establezco el color del texto como un atributo
            feature.set("Layer", texto.capa); // Establezco el nombre de la capa como un atributo

            textoLayer.getSource().addFeature(feature);
          });

          // Le aplico un nombre a la capa de texto
          textoLayer.set("name", "textoLayer");

          // Añado la capa de texto al mapa
          map2.addLayer(textoLayer);
        })
        .catch((error) => console.error(error));
    } else {
      //Obtengo el numero de option seleccionado
      let numeroOptionSeleccionado = event.target.selectedIndex;

      //Obtengo el angulo inicial del mapa
      const anguloInicial = map2.getView().getRotation();

      //Si es numero es entre 0 y 9, le añado un 0 delante
      if (numeroOptionSeleccionado < 10) {
        numeroOptionSeleccionado = "0" + numeroOptionSeleccionado;
      }

      //Borro el contenido que tenga en el mapa
      map2
        .getLayers()
        .getArray()
        .forEach((layer) => {
          layer.getSource().clear();
        });

      // Promesas para cargar el GeoJSON y los estilos
      /* const geoJsonPromise = fetch(
        `./planos/${ultimaReferenciaCatastralEnviada}.geojson`
      ).then((response) => response.json()); */
      let geoJsonFuncion = geojsonPorReferenciaCatastral(ultimaReferenciaCatastralEnviada); 
      let geoJsonPromise = geoJsonFuncion.then((geojson) => {
        return JSON.parse(geojson[0].geojson);
      });

      /* const estilosPromise = fetch("./estilosCapas.json").then((response) =>
        response.json()
      ); */

      // Esperar a que ambas promesas se resuelvan
      Promise.all([geoJsonPromise, estilosPromise])
        .then(([geoJsonData, estilosData]) => {
          // Parsea el GeoJSON y añade las características a la fuente de datos vectoriales
          const format = new GeoJSON();
          geoJsonData.features.forEach((feature) => {
            if (
              feature.properties.Layer.startsWith(
                `PS${numeroOptionSeleccionado}`
              )
            ) {
              const featureToAdd = format.readFeature(feature);

              // Obtengo el nombre de la capa
              let nombreCapa = featureToAdd.values_.Layer;

              // Obtengo los dos primeros caracteres
              let dosPrimerosCaracteres = nombreCapa.substring(0, 2);

              // Obtengo los dos últimos caracteres
              let dosUltimosCaracteres = nombreCapa.substring(
                nombreCapa.length - 2
              );

              // Busco en el json si algún elemento tiene como dos primeros caracteres "dosPrimerosCaracteres" y como dos últimos "dosUltimosCaracteres"
              let estilo = estilosData.estilos.find((element) => {
                let capa = element.capa;

                return (
                  capa.substring(0, 2) === dosPrimerosCaracteres &&
                  capa.substring(capa.length - 2) === dosUltimosCaracteres
                );
              });

              // Si se encuentra un estilo para la capa, se asigna el color y el lineDash al stroke
              if (estilo) {
                featureToAdd.setStyle(
                  new Style({
                    stroke: new Stroke({
                      color: estilo.color,
                      width: estilo.lineWidth,
                      lineDash: [
                        parseInt(estilo.lineDash),
                        parseInt(estilo.lineSeparation),
                      ],
                    }),
                  })
                );
              } else {
                // Si no se encuentra un estilo para la capa, se asigna un color y un lineDash predeterminados
                featureToAdd.setStyle(
                  new Style({
                    stroke: new Stroke({
                      color: "black",
                    }),
                  })
                );
              }

              vectorSource.addFeature(featureToAdd);
            }
          });

          // Ajusta la vista del mapa2 a la extensión del GeoJSON
          const extent = vectorSource.getExtent();
          const padding = [50, 50, 50, 50]; // top, right, bottom, left
          map2.getView().fit(extent, { padding: padding });

          // Guardo en un array los textos de cada feature junto con sus coordenadas y rotación
          const textos = geoJsonData.features
            //Muestro solo los textos que estén en la capa del filter
            .filter((feature) =>
              feature.properties.Layer.startsWith(
                `PS${numeroOptionSeleccionado}`
              )
            )
            .map((feature) => {
              return {
                texto: feature.properties.Text,
                coordenadas: feature.geometry.coordinates,
                rotacion: feature.properties.rotation || 0,
                scale: feature.properties.scale || 1,
                Layer: feature.properties.Layer,
              };
            });

          // Creo una nueva capa de textos y le doy 10 px de tamaño de letra con un stroke de 2px
          const textoLayer = new VectorLayer({
            source: new VectorSource(),
            style: function (feature, resolution) {
              const fontSize = 0.8 / resolution;
              const rotation = feature.get("rotacion");
              const scale = feature.get("scale");
              const layer = feature.get("Layer");

              /* const length =
                feature.get("texto") !== undefined
                  ? feature.get("texto").length
                  : 0;

              //Calculo el tamaño que ocupa cada letra
              const offsetX = (length * fontSize * scale) / 4; */
              //Busco en el json de estilos si hay algun estilo que empiece con las dos primeras letras de la capa y acabe con las dos ultimas
              let estilo = estilosData.estilos.find((element) => {
                let capa = element.capa;
                return (
                  capa.substring(0, 2) === layer.substring(0, 2) &&
                  capa.substring(capa.length - 2) ===
                    layer.substring(layer.length - 2)
                );
              });

              //Si hay un estilo para la capa, le aplico el color que tenga el estilo
              return new Style({
                text: new Text({
                  font: fontSize + "px Arial",
                  text: feature.get("texto"),
                  fill: new Fill({
                    color: estilo.color ? estilo.color : "black",
                  }),
                  rotation: /-AL$/.test(layer)
                    ? 0 - anguloInicial
                    : (360 - rotation) * (Math.PI / 180) - anguloInicial,
                  scale: scale,
                  /* offsetX: offsetX, */
                  rotateWithView: true,
                  offsetY: /-AS$/.test(layer)
                    ? -fontSize * 0.65
                    : /-AL$/.test(layer)
                    ? fontSize * 0.65
                    : 0,
                }),
              });
            },
          });

          // Añado los textos al textoLayer
          textos.forEach((texto) => {
            const feature = new Feature({
              geometry: new Point(texto.coordenadas),
            });
            feature.set("texto", texto.texto);
            feature.set("rotacion", texto.rotacion);
            feature.set("scale", texto.scale);
            feature.set("Layer", texto.Layer);
            textoLayer.getSource().addFeature(feature);
          });

          //Le aplico un nombre a la capa de texto
          textoLayer.set("name", "textoLayer");

          // Añado la capa de texto al mapa
          map2.addLayer(textoLayer);
        })
        .catch((error) => {
          
        }); 
    }
  });
  manejadorCambioCapas = true;
 }
}


















let arrReferenciasYaCargadas = [];
const addDibujo = document.getElementById("addDibujo");

/* alternarOrtofotoPlano.addEventListener("click", () => { */
  // Crea la fuente de datos vectoriales vacía
  
  //Al pulsar addDibujo se comprueba la capa seleccionada en el select cambiarCapa
  addDibujo.addEventListener("click", () => {
    const vectorSource = new VectorSource();
    
    //Añado arrReferenciasYaCargadas, si la ultimaReferenciaCatastralEnviada no está en el array, la añado. Si ya está, borro todas las capas de nombre ultimaReferenciaCatastralEnviada
    if (!arrReferenciasYaCargadas.includes(ultimaReferenciaCatastralEnviada)) {
      arrReferenciasYaCargadas.push(ultimaReferenciaCatastralEnviada);
    } else {
      //Borro todas las capas de nombre ultimaReferenciaCatastralEnviada
      map.getLayers().forEach((layer) => {
          try {
            if (layer.get("name") === ultimaReferenciaCatastralEnviada) {
              //Vacio la capa
              layer.getSource().clear();
              //Elimino la capa
              vectorSource.removeLayer(layer);
            }
          } catch (error) {
            
          }
      });
    }

    let geojsonsYAsc = geojsonPorReferenciaCatastral(ultimaReferenciaCatastralEnviada);

    //Obtengo el valor del select cambiarCapa
    let capaSeleccionada = document.getElementById("cambiarCapa").value;
    let nombreCapaGeojson = "";

    if (capaSeleccionada === "Planta General") {
      nombreCapaGeojson = "PG-";
    } else{
      //Obtengo el numero de posicion del select
      let numeroOptionSeleccionado = document.getElementById("cambiarCapa").selectedIndex;
      //Si es numero es entre 0 y 9, le añado un 0 delante
      if (numeroOptionSeleccionado < 10) {
        numeroOptionSeleccionado = "0" + numeroOptionSeleccionado;
      }
      nombreCapaGeojson = `PS${numeroOptionSeleccionado}-`;
    }

    geojsonsYAsc.then((geojson) => {
      let geojsonData = JSON.parse(geojson[0].geojson);
      let ascData = geojson[0].asci;

      //Proceso el ASC Data
      let lineas = ascData.split("\n");
      let tipoVia = lineas[5];
      let nombreVia = lineas[6];
      let numeroVia = lineas[7].replace(/^0+/, "");
      let letraVia = lineas[8];
      let nombreViaCompleto = `${tipoVia.trim()}, ${nombreVia} ${numeroVia} ${
        letraVia === " " ? "" : letraVia
      }`;
    
      //Obtengo solo las features que tengan como Layer el nombre de la capa seleccionada
      let featuresFiltradas = geojsonData.features.filter((feature) => feature.properties.Layer.startsWith(nombreCapaGeojson));
      
      //Parsear el GeoJSON y añadir las características a la fuente de datos vectoriales
      const format = new GeoJSON();
      featuresFiltradas.forEach((feature) => {
        /* if(feature.properties.Layer.startsWith("PG-")){ */
        const featureToAdd = format.readFeature(feature);

        //Obtengo los dos primeros y los ultimos caracteres del nombre de la capa
        let dosPrimerosCaracteres = featureToAdd.values_.Layer.substring(0, 2);
        let dosUltimosCaracteres = featureToAdd.values_.Layer.substring(featureToAdd.values_.Layer.length - 2);

        let estilo = estilosPromise.estilos.find((element) => {
          let capa = element.capa;
          return capa.substring(0, 2) === dosPrimerosCaracteres && capa.substring(capa.length - 2) === dosUltimosCaracteres;
        });

        if (estilo) {
          featureToAdd.setStyle(
            new Style({
              stroke: new Stroke({
                color: estilo.color,
                width: estilo.lineWidth,
                lineDash: [parseInt(estilo.lineDash), parseInt(estilo.lineSeparation)]
              })
            })
          );
        } else {
          featureToAdd.setStyle(
            new Style({
              stroke: new Stroke({
                color: "black"
              })
            })
          );
        }

        vectorSource.addFeature(featureToAdd);
        /* } */
      });

      //Añadir la fuente de datos vectoriales al mapa
      const vectorLayer = new VectorLayer({
        source: vectorSource,
        name: ultimaReferenciaCatastralEnviada
      });

      map.addLayer(vectorLayer);

      //Subo el zIndex de la capa de geometría para que se muestre por encima de la capa de texto
      vectorLayer.setZIndex(3);

      //Comprueba si existe alguna capa PG-LF
      let capaPG_LF = geojsonData.features.find((feature) => feature.properties.Layer === "PG-LF") ? true : false;

      if (nombreCapaGeojson === "PG-") {
      if (capaPG_LF) {
        let angle = 0;
        geojsonData.features.forEach((feature) => {
          //Para la línea que se encuentra en la capa PG-LF, obtengo el ángulo que forma con la horizontal y lo guardo en una variable
          if (feature.properties.Layer === "PG-LF") {
            const geometry = feature.geometry;
            const coordinates = geometry.coordinates;
            angle = Math.atan2(coordinates[1][1] - coordinates[0][1], coordinates[1][0] - coordinates[0][0]);

            //Ajustar el ángulo si es necesario
            if (Math.abs(angle) > 0 && Math.abs(angle) <= Math.PI / 2) {
              angle = angle - Math.PI / 2;
            } else if (Math.abs(angle) > Math.PI / 2 && Math.abs(angle) <= Math.PI) {
              angle = angle - Math.PI / 2;
            } else if (Math.abs(angle) > Math.PI && Math.abs(angle) <= 3 * Math.PI / 2) {
              angle = angle - Math.PI / 2;
            } else if (Math.abs(angle) > 3 * Math.PI / 2 && Math.abs(angle) <= 2 * Math.PI) {
              angle = angle - Math.PI / 2;
            } else {
              angle = angle;
            }

            //Obtener la posición central de la capa PG-LF
            const centroX = (geometry.coordinates[0][0] + geometry.coordinates[1][0]) / 2;
            const centroY = (geometry.coordinates[0][1] + geometry.coordinates[1][1]) / 2;

            //Posicionar el texto con el nombre de la vía en el centro de la layer PG-LF
            const point = new Point([centroX, centroY]);
            const textFeature = new Feature(point);
            textFeature.setStyle(
              new Style({
                text: new Text({
                  text: nombreViaCompleto,
                  font: "bold 14px Arial",
                  fill: new Fill({
                    color: "blue"
                  })
                })
              })
            );

            const vectorSourceTextoCalle = new VectorSource({
              features: [textFeature]
            });

            const vectorLayerTextoCalle = new VectorLayer({
              source: vectorSourceTextoCalle,
              name: ultimaReferenciaCatastralEnviada
            });

            //Aumento el zIndex de la capa de texto para que se muestre por encima de la capa de geometría
            vectorLayerTextoCalle.setZIndex(3);

            map.addLayer(vectorLayerTextoCalle);
          }
        })
      } else {
        //Añadir al GeoJSON el nombre de la vía
        const texto = nombreViaCompleto;

        //Obtener los límites máximos y mínimos del GeoJSON
        const extent = vectorSource.getExtent();

        //Guardar en una variable el punto central del eje x del GeoJSON
        const centroX = (extent[0] + extent[2]) / 2;

        //Guardar en una variable el punto más bajo del eje y del GeoJSON
        const puntoMasBajo = extent[1];

        //Pintar el texto en el mapa en el punto seleccionado
        const point = new Point([centroX, puntoMasBajo]);

        const textFeature = new Feature(point);

        textFeature.setStyle(
          new Style({
            text: new Text({
              text: texto,
              font: "bold 14px Arial",
              fill: new Fill({
                color: "blue"
              })
            })
          })
        );

        const vectorSourceTextoCalle = new VectorSource({
          features: [textFeature]
        });

        const vectorLayerTextoCalle = new VectorLayer({
          source: vectorSourceTextoCalle,
          name: ultimaReferenciaCatastralEnviada
        });

        map.addLayer(vectorLayerTextoCalle);

        //Aumento el zIndex de la capa de texto para que se muestre por encima de la capa de geometría
        vectorLayerTextoCalle.setZIndex(3);
      }
    }

      //Guardar en un array los textos de cada feature junto con sus coordenadas y rotación
      const textos = featuresFiltradas
        .map((feature) => {
          return {
            texto: feature.properties.Text,
            coordenadas: feature.geometry.coordinates,
            rotacion: feature.properties.rotation || 0,
            scale: feature.properties.scale || 1,
            capa: feature.properties.Layer
          };
        });

      //Crear una capa vectorial para los textos
      const textoVectorLayer = new VectorLayer({
        source: new VectorSource(),
        name: ultimaReferenciaCatastralEnviada //Asignar un nombre a la capa
      });

      //Agregar la capa al mapa
      map.addLayer(textoVectorLayer);

      //Subo el zIndex de la capa de texto para que se muestre por encima de la capa de geometría
      textoVectorLayer.setZIndex(3);
   
      const textoLayer = new VectorLayer({
        source: new VectorSource(),
        style: function (feature, resolution) {
          const fontSize = 2 / resolution; //2 es el tamaño de fuente base
          const rotation = feature.get("rotacion");
          const scale = feature.get("scale");
          const layer = feature.get("Layer");
            return new Style({
            text: new Text({
              font: fontSize + "px Arial",
              text: feature.get("texto"),
              fill: new Fill({ color: feature.get("color") }), // Recupera el color del texto desde el atributo "color"

              // Aplica la rotación del mapa
              rotation: /-AL$/.test(layer)
                    ? 0
                    : (360 - rotation) * (Math.PI / 180),
              /* offsetX: offsetX, */
              rotateWithView: true,
              offsetY: /-AS$/.test(layer)
              ? -fontSize * 0.8
              : /-AL$/.test(layer)
              ? fontSize * 0.8
              : 0,
            }),
            });
        },
      });

      //Añado los textos al textoLayer
      textos.forEach((texto) => {
        //Guardo los dos primeros y los dos ultimos caracteres del nombre de la capa
        let dosPrimerosCaracteres = texto.capa.substring(0, 2);
        let dosUltimosCaracteres = texto.capa.substring(texto.capa.length - 2);

        //Busco en el json de estilos si hay algun estilo que empiece con las dos primeras letras de la capa y acabe con las dos ultimas
        let estilo = estilosPromise.estilos.find((element) => { 
          let capa = element.capa;
          return capa.substring(0, 2) === dosPrimerosCaracteres && capa.substring(capa.length - 2) === dosUltimosCaracteres;
        });

        //Agrego el color del texto al objeto de texto
        texto.color = estilo ? estilo.color : "black";

        const feature = new Feature({
          geometry: new Point(texto.coordenadas),
        });
        feature.set("texto", texto.texto);
        feature.set("rotacion", texto.rotacion);
        feature.set("scale", texto.scale);
        feature.set("color", texto.color); //Establezco el color del texto como un atributo
        feature.set("Layer", texto.capa); //Establezco el nombre de la capa como un atributo

        textoLayer.getSource().addFeature(feature);
      });

      textoLayer.set("name", ultimaReferenciaCatastralEnviada);

      map.addLayer(textoLayer);

      //Subo el zIndex de la capa de texto para que se muestre por encima de la capa de geometría
      textoLayer.setZIndex(3);    
    });
  });
  /* comprobarEjecutado = false; */
/* }); */


// Al pulsar borrarDibujo se elimina el contenido de la capa capaGeojsonsDibujos
const borrarDibujo = document.getElementById("borrarDibujo");
borrarDibujo.addEventListener("click", () => {
  // Recorro las capas del mapa
  map.getLayers().forEach((layer) => {
    // Si la capa se llama capaGeojsonsDibujos o está en el array arrReferenciasYaCargadas
    if (layer.get("name") === ultimaReferenciaCatastralEnviada || arrReferenciasYaCargadas.includes(layer.get("name"))) {
      // Borro el contenido de la capa
      layer.getSource().clear();
    }
  });
  // Vacío el array arrReferenciasYaCargadas
  arrReferenciasYaCargadas = [];
});