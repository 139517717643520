import cargaReferenciaCatastralPorVia from "./cargaReferenciaCatastralPorVia";

const urlImagenPorReferenciaCatastral = (referenciaCatastral) => {
  // Hago una petición al servidor para obtener el listado de imagenes a partir de la referencia catastral
  const url = `./Request/enc/cargaListadoImagenesCifrado.php`;

  const xhr = new XMLHttpRequest();
  xhr.open("POST", url);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send(`referenciaCatastral=${referenciaCatastral}`);

  return new Promise((resolve, reject) => {
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const imagenes = JSON.parse(xhr.responseText);
          // Creo un array con los nombres de las imagenes
          const nombresImagenes = imagenes.map((imagen) => imagen.nombreImagen);

          // Creo un array de promesas para cargar cada imagen
          const promesasCargaImagenes = nombresImagenes.map((nombreImagen) => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = `./Ibifot/${nombreImagen}`;
              img.onload = function () {
                resolve(nombreImagen); // Resuelve la promesa cuando la imagen se carga correctamente
              };
              img.onerror = function () {
                resolve(null); // Resuelve la promesa con null cuando la imagen no se carga correctamente
              };
            });
          });

          // Espero a que todas las promesas de carga de imágenes se resuelvan
          Promise.all(promesasCargaImagenes).then((resultados) => {
            // Filtrar los nombres de imágenes que se cargaron correctamente (resultados no son null)
            const nombresImagenesCargadas = resultados.filter((nombre) => nombre !== null);
            resolve(nombresImagenesCargadas);
          });
        } else {
          reject("Error");
        }
      }
    };
  });
};

//Evento que se lanza al pulsar el boton de enviar
document.getElementById("enviar").addEventListener("click", () => {
  let arrImagenes = [];
  //Recupera el valor del input de referencia catastral y lanzo la funcion urlImagenPorReferenciaCatastral con ese valor
  const referenciaCatastral = document.getElementById(
    "valorReferenciaCatastral"
  ).value;

  // Comprueba que la referencia catastral coincida con alguna del datalist
  const opcionesReferenciaCatastral = document.getElementById(
    "tiposReferenciaCatastral"
  );
  let referenciaCatastralCorrecta = false;
  opcionesReferenciaCatastral.childNodes.forEach((element) => {
    if (referenciaCatastral == element.value) {
      referenciaCatastralCorrecta = true;
    }
  });

  // Comprueba que el nombre de via coincida con alguna del datalist
  const opcionesNombreVia = document.getElementById("nombresVias").childNodes,
    nombreVia = document.getElementById("valorNombreVia");
  let nombreViaCorrecto = false;
  opcionesNombreVia.forEach((element) => {
    if (nombreVia.value == element.value) {
      nombreViaCorrecto = true;
    }
  });

  if (referenciaCatastralCorrecta) {
    arrImagenes = urlImagenPorReferenciaCatastral(referenciaCatastral);

    //Si la promesa se resuelve correctamente, muestro las imagenes
    arrImagenes.then((imagenes) => {
      //Si no se encuentra ninguna imagen se muestra un mensaje avisando
      if (imagenes.length === 0) {
        const containerImagen = document.getElementById("containerImagen");
        containerImagen.innerHTML = `<p>No hay imagenes guardadas para esta referencia catastral</p>`;
      }

      //Si solo se encuentra una imagen se muestra en el div de id "containerImagen"
      if (imagenes.length === 1) {
        const containerImagen = document.getElementById("containerImagen");
        containerImagen.innerHTML = `<img class="w-full h-auto" src="./Ibifot/${imagenes[0]}" alt="imagen_parcela_seleccionada" />`;
      }

      // Si el array de imágenes tiene 2 o más elementos, se muestran controles para pasar de una imagen a otra
      if (imagenes.length > 1) {
        const containerImagen = document.getElementById("containerImagen");
        containerImagen.innerHTML = `<img id="imagenParcela" class="w-full h-auto" src="./Ibifot/${imagenes[0]}" alt="imagen_parcela_seleccionada" />`;
        containerImagen.innerHTML += `<div class="flex justify-around pt-2">
                <button id="botonAnterior" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
                    </svg>
                </button>
                <button id="botonSiguiente" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </button>
            </div>`;

        // Previene la acción por defecto del los botones
        document
          .getElementById("botonAnterior")
          .addEventListener("click", (event) => {
            event.preventDefault();
          });
        document
          .getElementById("botonSiguiente")
          .addEventListener("click", (event) => {
            event.preventDefault();
          });

        // Al hacer click en el botón siguiente, se muestra la siguiente imagen
        document
          .getElementById("botonSiguiente")
          .addEventListener("click", () => {
            const imagen = document.getElementById("imagenParcela");
            const srcImagen = imagen.src;
            const nombreImagen = srcImagen.substring(
              srcImagen.lastIndexOf("/") + 1
            );

            const indiceImagen = imagenes.indexOf(nombreImagen);

            if (indiceImagen < imagenes.length - 1) {
              imagen.src = `./Ibifot/${
                imagenes[indiceImagen + 1]
              }`;
            } else {
              imagen.src = `./Ibifot/${imagenes[0]}`;
            }
          });

        // Al hacer click en el botón anterior, se muestra la imagen anterior
        document
          .getElementById("botonAnterior")
          .addEventListener("click", () => {
            const imagen = document.getElementById("imagenParcela");
            const srcImagen = imagen.src;
            const nombreImagen = srcImagen.substring(
              srcImagen.lastIndexOf("/") + 1
            );

            const indiceImagen = imagenes.indexOf(nombreImagen);

            if (indiceImagen > 0) {
              imagen.src = `./Ibifot/${
                imagenes[indiceImagen - 1]
              }`;
            } else {
              imagen.src = `./Ibifot/${
                imagenes[imagenes.length - 1]
              }`;
            }
          });
      }
      // Al hacer click en el containerImagen, se muestra un modal con la imagen a tamaño completo y los botones para pasar de una imagen a otra

      //Variable que guarda el total de imagenes
      const totalImagenes = imagenes.length;

      //En caso de tener solo una imagen se guarda una variable vacia, en caso de tener más se guardan los controles para pasar de una imagen a otra
      let controles = "",
        controlesGirar = "";

      if (totalImagenes > 1) {
        controles = `
        <div class="flex justify-around pt-2">
          <button id="botonAnteriorModal" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 mr-2 rounded-lg w-20 h-12 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
              </svg>
          </button>
          <button id="botonSiguienteModal" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 ml-2 rounded-lg w-20 h-12 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
              </svg>
          </button>
        </div>`;
      }

      controlesGirar = `
      <div class="flex justify-around pt-2">
        <button id="botonGirarIzquierda" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 mr-2 rounded-lg w-20 h-12 flex items-center justify-center">
              <svg width="32px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 20.5C17.1944 20.5 21 16.6944 21 12C21 7.30558 17.1944 3.5 12.5 3.5C7.80558 3.5 4 7.30558 4 12C4 13.5433 4.41128 14.9905 5.13022 16.238M1.5 15L5.13022 16.238M6.82531 12.3832L5.47107 16.3542L5.13022 16.238" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <button id="botonGirarDerecha" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 ml-2 rounded-lg w-20 h-12 flex items-center justify-center">
              <svg width="800px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.5 20.5C6.80558 20.5 3 16.6944 3 12C3 7.30558 6.80558 3.5 11.5 3.5C16.1944 3.5 20 7.30558 20 12C20 13.5433 19.5887 14.9905 18.8698 16.238M22.5 15L18.8698 16.238M17.1747 12.3832L18.5289 16.3542L18.8698 16.238" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
        </button>
      </div>`;

      if (
        document.getElementById("containerImagen").querySelector("img") !== null
      ) {
        document
          .getElementById("containerImagen")
          .querySelector("img")
          .addEventListener("click", () => {
            const modal = `
            <!-- Main modal -->
            <div id="modalInfoParcela" tabindex="-1" class="fixed inset-0 flex items-center justify-center z-50 mx-2">
              <div class="bg-white rounded-lg shadow-lg w-full max-w-2xl">
                <!-- Modal content -->
                <div class="p-4 md:p-5">
                  <!-- Modal header -->
                  <div class="flex items-center justify-between border-b pb-4">
                    <h3 class="text-xl font-semibold text-gray-900">
                      Referencia catastral: ${referenciaCatastral}
                    </h3>
                    <button type="button" id="cerrarModal" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                      <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                      </svg>
                      <span class="sr-only">Close modal</span>
                    </button>
                  </div>
                  <!-- Modal body -->
                  <div id="divImagenesYControles" class="h-[480px] p-1 md:p-5 space-y-4 flex items-center justify-center flex-col">
                    <img id="modalImagen" class="h-[380px]" src="./Ibifot/${imagenes[0]}" alt="imagen_parcela_seleccionada" style="object-fit: contain;"/>
                    <div class="flex flex-row w-full justify-around">
                      ${controles}
                      ${controlesGirar}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          `;

            //Muestro el modal por encima de todo el demas contenido
            document.body.insertAdjacentHTML("beforeend", modal);

            // Al pulsar el botón de cerrar modal, se elimina el modal
            document
              .getElementById("cerrarModal")
              .addEventListener("click", () => {
                document.getElementById("modalInfoParcela").remove();
              });

            // Al pulsar el botón siguiente, se muestra la siguiente imagen
            if (document.getElementById("botonSiguienteModal") !== null) {
              document
                .getElementById("botonSiguienteModal")
                .addEventListener("click", () => {
                  const imagen = document.getElementById("modalImagen");
                  const srcImagen = imagen.src;
                  const nombreImagen = srcImagen.substring(
                    srcImagen.lastIndexOf("/") + 1
                  );

                  const indiceImagen = imagenes.indexOf(nombreImagen);

                  if (indiceImagen < imagenes.length - 1) {
                    imagen.src = `./Ibifot/${
                      imagenes[indiceImagen + 1]
                    }`;
                  } else {
                    imagen.src = `./Ibifot/${imagenes[0]}`;
                  }
                });

              // Al pulsar el botón anterior, se muestra la imagen anterior
              document
                .getElementById("botonAnteriorModal")
                .addEventListener("click", () => {
                  const imagen = document.getElementById("modalImagen");
                  const srcImagen = imagen.src;
                  const nombreImagen = srcImagen.substring(
                    srcImagen.lastIndexOf("/") + 1
                  );

                  const indiceImagen = imagenes.indexOf(nombreImagen);

                  if (indiceImagen > 0) {
                    imagen.src = `./Ibifot/${
                      imagenes[indiceImagen - 1]
                    }`;
                  } else {
                    imagen.src = `./Ibifot/${
                      imagenes[imagenes.length - 1]
                    }`;
                  }
                });
            }

            document
              .getElementById("botonGirarIzquierda")
              .addEventListener("click", () => {
                const imagen = document.getElementById("modalImagen");
                const currentRotation = parseInt(imagen.dataset.rotation) || 0;
                const anchoImagen = imagen.width;
                const escala = 380 / anchoImagen;
                const newRotation = (currentRotation - 90) % 360;

                if (
                  newRotation === 0 ||
                  newRotation === -180 ||
                  newRotation === 180
                ) {
                  imagen.style.transform = `rotate(${newRotation}deg) scale(1)`;
                  imagen.dataset.rotation = newRotation;
                } else {
                  imagen.style.transform = `rotate(${newRotation}deg) scale(${escala})`;
                  imagen.dataset.rotation = newRotation;
                }
              });

            // Al pulsar el botón de girar a la derecha, se gira la imagen 90 grados a la derecha
            document
              .getElementById("botonGirarDerecha")
              .addEventListener("click", () => {
                const imagen = document.getElementById("modalImagen");
                const currentRotation = parseInt(imagen.dataset.rotation) || 0;
                const anchoImagen = imagen.width;
                const escala = 380 / anchoImagen;
                const newRotation = (currentRotation + 90) % 360;

                if (
                  newRotation === 0 ||
                  newRotation === -180 ||
                  newRotation === 180
                ) {
                  imagen.style.transform = `rotate(${newRotation}deg) scale(1)`;
                  imagen.dataset.rotation = newRotation;
                } else {
                  imagen.style.transform = `rotate(${newRotation}deg) scale(${escala})`;
                  imagen.dataset.rotation = newRotation;
                }
              });
          });
      }
    });
  } else if (nombreViaCorrecto) {
    //Devuelvo el resultado de cargaReferenciaCatastralPorVia cuando se resuelva la promesa
    cargaReferenciaCatastralPorVia().then((referenciaCatastralObtenida) => {
      arrImagenes = urlImagenPorReferenciaCatastral(referenciaCatastralObtenida);

    //Si la promesa se resuelve correctamente, muestro las imagenes
    arrImagenes.then((imagenes) => {
      //Si no se encuentra ninguna imagen se muestra un mensaje avisando
      if (imagenes.length === 0) {
        const containerImagen = document.getElementById("containerImagen");
        containerImagen.innerHTML = `<p>No hay imagenes guardadas para esta referencia catastral</p>`;
      }

      //Si solo se encuentra una imagen se muestra en el div de id "containerImagen"
      if (imagenes.length === 1) {
        const containerImagen = document.getElementById("containerImagen");
        containerImagen.innerHTML = `<img class="w-full h-auto" src="./Ibifot/${imagenes[0]}" alt="imagen_parcela_seleccionada" />`;
      }

      // Si el array de imágenes tiene 2 o más elementos, se muestran controles para pasar de una imagen a otra
      if (imagenes.length > 1) {
        const containerImagen = document.getElementById("containerImagen");
        containerImagen.innerHTML = `<img id="imagenParcela" class="w-full h-auto" src="./Ibifot/${imagenes[0]}" alt="imagen_parcela_seleccionada" />`;
        containerImagen.innerHTML += `<div class="flex justify-around pt-2">
                <button id="botonAnterior" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
                    </svg>
                </button>
                <button id="botonSiguiente" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </button>
            </div>`;

        // Previene la acción por defecto del los botones
        document
          .getElementById("botonAnterior")
          .addEventListener("click", (event) => {
            event.preventDefault();
          });
        document
          .getElementById("botonSiguiente")
          .addEventListener("click", (event) => {
            event.preventDefault();
          });

        // Al hacer click en el botón siguiente, se muestra la siguiente imagen
        document
          .getElementById("botonSiguiente")
          .addEventListener("click", () => {
            const imagen = document.getElementById("imagenParcela");
            const srcImagen = imagen.src;
            const nombreImagen = srcImagen.substring(
              srcImagen.lastIndexOf("/") + 1
            );

            const indiceImagen = imagenes.indexOf(nombreImagen);

            if (indiceImagen < imagenes.length - 1) {
              imagen.src = `./Ibifot/${
                imagenes[indiceImagen + 1]
              }`;
            } else {
              imagen.src = `./Ibifot/${imagenes[0]}`;
            }
          });

        // Al hacer click en el botón anterior, se muestra la imagen anterior
        document
          .getElementById("botonAnterior")
          .addEventListener("click", () => {
            const imagen = document.getElementById("imagenParcela");
            const srcImagen = imagen.src;
            const nombreImagen = srcImagen.substring(
              srcImagen.lastIndexOf("/") + 1
            );

            const indiceImagen = imagenes.indexOf(nombreImagen);

            if (indiceImagen > 0) {
              imagen.src = `./Ibifot/${
                imagenes[indiceImagen - 1]
              }`;
            } else {
              imagen.src = `./Ibifot/${
                imagenes[imagenes.length - 1]
              }`;
            }
          });
      }
      // Al hacer click en el containerImagen, se muestra un modal con la imagen a tamaño completo y los botones para pasar de una imagen a otra

      //Variable que guarda el total de imagenes
      const totalImagenes = imagenes.length;

      //En caso de tener solo una imagen se guarda una variable vacia, en caso de tener más se guardan los controles para pasar de una imagen a otra
      let controles = "",
        controlesGirar = "";

      if (totalImagenes > 1) {
        controles = `
        <div class="flex justify-around pt-2">
          <button id="botonAnteriorModal" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 mr-2 rounded-lg w-20 h-12 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
              </svg>
          </button>
          <button id="botonSiguienteModal" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 ml-2 rounded-lg w-20 h-12 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
              </svg>
          </button>
        </div>`;
      }

      controlesGirar = `
      <div class="flex justify-around pt-2">
        <button id="botonGirarIzquierda" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 mr-2 rounded-lg w-20 h-12 flex items-center justify-center">
              <svg width="32px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 20.5C17.1944 20.5 21 16.6944 21 12C21 7.30558 17.1944 3.5 12.5 3.5C7.80558 3.5 4 7.30558 4 12C4 13.5433 4.41128 14.9905 5.13022 16.238M1.5 15L5.13022 16.238M6.82531 12.3832L5.47107 16.3542L5.13022 16.238" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <button id="botonGirarDerecha" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 ml-2 rounded-lg w-20 h-12 flex items-center justify-center">
              <svg width="800px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.5 20.5C6.80558 20.5 3 16.6944 3 12C3 7.30558 6.80558 3.5 11.5 3.5C16.1944 3.5 20 7.30558 20 12C20 13.5433 19.5887 14.9905 18.8698 16.238M22.5 15L18.8698 16.238M17.1747 12.3832L18.5289 16.3542L18.8698 16.238" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
        </button>
      </div>`;

      if (
        document.getElementById("containerImagen").querySelector("img") !== null
      ) {
        document
          .getElementById("containerImagen")
          .querySelector("img")
          .addEventListener("click", () => {
            const modal = `
            <!-- Main modal -->
            <div id="modalInfoParcela" tabindex="-1" class="fixed inset-0 flex items-center justify-center z-50">
              <div class="bg-white rounded-lg shadow-lg w-full max-w-2xl">
                <!-- Modal content -->
                <div class="p-4 md:p-5">
                  <!-- Modal header -->
                  <div class="flex items-center justify-between border-b pb-4">
                    <h3 class="text-xl font-semibold text-gray-900">
                      Referencia catastral: ${referenciaCatastral}
                    </h3>
                    <button type="button" id="cerrarModal" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                      <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                      </svg>
                      <span class="sr-only">Close modal</span>
                    </button>
                  </div>
                  <!-- Modal body -->
                  <div id="divImagenesYControles" class="h-[480px] p-1 md:p-5 space-y-4 flex items-center justify-center flex-col">
                    <img id="modalImagen" class="h-[380px]" src="./Ibifot/${imagenes[0]}" alt="imagen_parcela_seleccionada" />
                    <div class="flex flex-row w-full justify-around">
                      ${controles}
                      ${controlesGirar}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          `;

            //Muestro el modal por encima de todo el demas contenido
            document.body.insertAdjacentHTML("beforeend", modal);

            // Al pulsar el botón de cerrar modal, se elimina el modal
            document
              .getElementById("cerrarModal")
              .addEventListener("click", () => {
                document.getElementById("modalInfoParcela").remove();
              });

            // Al pulsar el botón siguiente, se muestra la siguiente imagen
            if (document.getElementById("botonSiguienteModal") !== null) {
              document
                .getElementById("botonSiguienteModal")
                .addEventListener("click", () => {
                  const imagen = document.getElementById("modalImagen");
                  const srcImagen = imagen.src;
                  const nombreImagen = srcImagen.substring(
                    srcImagen.lastIndexOf("/") + 1
                  );

                  const indiceImagen = imagenes.indexOf(nombreImagen);

                  if (indiceImagen < imagenes.length - 1) {
                    imagen.src = `./Ibifot/${
                      imagenes[indiceImagen + 1]
                    }`;
                  } else {
                    imagen.src = `./Ibifot/${imagenes[0]}`;
                  }
                });

              // Al pulsar el botón anterior, se muestra la imagen anterior
              document
                .getElementById("botonAnteriorModal")
                .addEventListener("click", () => {
                  const imagen = document.getElementById("modalImagen");
                  const srcImagen = imagen.src;
                  const nombreImagen = srcImagen.substring(
                    srcImagen.lastIndexOf("/") + 1
                  );

                  const indiceImagen = imagenes.indexOf(nombreImagen);

                  if (indiceImagen > 0) {
                    imagen.src = `./Ibifot/${
                      imagenes[indiceImagen - 1]
                    }`;
                  } else {
                    imagen.src = `./Ibifot/${
                      imagenes[imagenes.length - 1]
                    }`;
                  }
                });
            }

            document
              .getElementById("botonGirarIzquierda")
              .addEventListener("click", () => {
                const imagen = document.getElementById("modalImagen");
                const currentRotation = parseInt(imagen.dataset.rotation) || 0;
                const anchoImagen = imagen.width;
                const escala = 380 / anchoImagen;
                const newRotation = (currentRotation - 90) % 360;

                if (
                  newRotation === 0 ||
                  newRotation === -180 ||
                  newRotation === 180
                ) {
                  imagen.style.transform = `rotate(${newRotation}deg) scale(1)`;
                  imagen.dataset.rotation = newRotation;
                } else {
                  imagen.style.transform = `rotate(${newRotation}deg) scale(${escala})`;
                  imagen.dataset.rotation = newRotation;
                }
              });

            // Al pulsar el botón de girar a la derecha, se gira la imagen 90 grados a la derecha
            document
              .getElementById("botonGirarDerecha")
              .addEventListener("click", () => {
                const imagen = document.getElementById("modalImagen");
                const currentRotation = parseInt(imagen.dataset.rotation) || 0;
                const anchoImagen = imagen.width;
                const escala = 380 / anchoImagen;
                const newRotation = (currentRotation + 90) % 360;

                if (
                  newRotation === 0 ||
                  newRotation === -180 ||
                  newRotation === 180
                ) {
                  imagen.style.transform = `rotate(${newRotation}deg) scale(1)`;
                  imagen.dataset.rotation = newRotation;
                } else {
                  imagen.style.transform = `rotate(${newRotation}deg) scale(${escala})`;
                  imagen.dataset.rotation = newRotation;
                }
              });
          });
      }
    });
      
    });
  }
});
