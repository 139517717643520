import proj4 from "proj4";

/* // Definir las proyecciones
const utmProjection = "+proj=utm +zone=30 +ellps=WGS84 +datum=WGS84 +units=m +no_defs";
const geoProjection = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";

// Coordenadas UTM (Ejemplo)
let utmCoordinates = [393293.900, 4803835.630];

// Transformar las coordenadas
let geoCoordinates = proj4(utmProjection, geoProjection, utmCoordinates);

console.log(geoCoordinates); // [longitud, latitud] */

const obtenerCoordenadasMapsInterno = (coordenadasUTM) => {
  // Definir las proyecciones
  const utmProjection =
    "+proj=utm +zone=30 +ellps=WGS84 +datum=WGS84 +units=m +no_defs";
  const geoProjection = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";

  let utmCoordinates = coordenadasUTM;

  let geoCoordinates = proj4(utmProjection, geoProjection, utmCoordinates);

  return geoCoordinates;
};

// Función que recupera las coordenadas a partir de la referencia catastral haciendo una petición contra el catastro
/* const obtenerCoordenadasMaps = (referenciaCatastral) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      `https://ovc.catastro.meh.es/OVCServWeb/OVCWcfCallejero/COVCCoordenadas.svc/json/Consulta_CPMRC?Provincia=Cantabria&Municipio=Comillas&SRS=EPSG:4326&RefCat=${referenciaCatastral}`
    );
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    xhr.onload = function () {
      if (xhr.status === 200) {
        const respuesta = JSON.parse(xhr.responseText);
        if (respuesta.length !== 0) {
          const coordenadas = [respuesta.Consulta_CPMRCResult.coordenadas.coord[0].geo.xcen, respuesta.Consulta_CPMRCResult.coordenadas.coord[0].geo.ycen];
          resolve(coordenadas);
        } else {
          reject("No se encontraron coordenadas para la referencia catastral proporcionada.");
        }
      } else {
        reject("Error en la solicitud. Estado devuelto: " + xhr.status);
      }
    };

    xhr.onerror = function () {
      reject("Error en la solicitud. No se pudo completar la petición.");
    };

    xhr.send();
  });
}; */

//Función que recupera los datos de la tabla informacionASC y carga un modal con los datos
export default function cargaDatosASC(simbolos) {
  let referenciaCatastral = "";
  //Creo una petición al servidor para obtener los datos de la vía a partir de las coordenadas del símbolo
  const xhr = new XMLHttpRequest();
  xhr.open(
    "POST",
    "./Request/enc/cargaDatosPorCoordenadasCifrado.php"
  );
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onload = function () {
    if (xhr.status === 200) {
      const respuesta = JSON.parse(xhr.responseText);
      if (respuesta.length !== 0) {
        referenciaCatastral =
          respuesta[0].referenCiaCatastral1 + respuesta[0].referenCiaCatastral2;

        const coordenada1 = parseFloat(
          simbolos.values_.geometry.flatCoordinates[0]
        );
        const coordenada2 = parseFloat(
          simbolos.values_.geometry.flatCoordinates[1]
        );

        const arrCoordenadasGTM = obtenerCoordenadasMapsInterno([
          coordenada1,
          coordenada2,
        ]);

        /* obtenerCoordenadasMaps(referenciaCatastral).then((coordenadas) => { */
        const modal = `
            <!-- Main modal -->
            <div id="modalInfoParcela" tabindex="-1" class="fixed inset-0 flex items-center justify-center z-50 mx-2">
            <div class="bg-white rounded-lg shadow-lg w-full max-w-lg">
            <!-- Modal content -->
            <div class="p-4 md:p-5">
            <!-- Modal header -->
            <div class="flex items-center justify-between">
            
                  <button type="button" id="cerrarModal" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                    <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <p class='py-0.5 px-4'>Dirección: <b>${respuesta[0].tipoVia}, ${
          respuesta[0].nombreCalle
        } ${respuesta[0].numeroCalle} ${
          respuesta[0].letraCalle === " " ? "" : respuesta[0].letraCalle
        }</b></p>
        ${
          respuesta[0].codigoPostal === "0"
            ? ""
            : `<p class='py-0.5 px-4'>Código postal: <b>${respuesta[0].codigoPostal}</b></p>`
        }
                <!-- Modal body -->
                <div class="p-4 md:p-5 space-y-4">
                    <div class="text-base leading-relaxed text-gray-700 flex flex-row">
                        <div class="flex justify-start w-2/3 flex-col h-28">
                          <p class='py-0.5 pr-4'>Parcela catastral: <b id="referenciaCatastralBModal">${referenciaCatastral}</b></p>
                          <div class="flex justify-center items-center mt-2">
                                  <button id="modalVerDibujo" class="flex justify-center items-center flex-row px-4 py-4 bg-blue-700 hover:bg-blue-800 text-white rounded-lg w-40 h-10">
                                    <img class="w-6 h-6 me-2 rounded-sm" src="./iconos/LogotipoTEFICAR.png" alt="Ver dibujo">
                                     VisualCu1
                                  </button>
                          </div>
                        </div>
                        <div class="w-1/3 h-28" id="divImagen"></div>
                    </div>
                        <div class="flex justify-center">
  
                        <button id="dropdownUsersButton" data-dropdown-toggle="dropdownUsers" data-dropdown-placement="bottom" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mt-8 h-10 w-40 relative" type="button" style="justify-content: center;">
                          Ver más... 
                          <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                          </svg>
                        </button>

                        <!-- Dropdown menu -->
                        <div id="dropdownUsers" class="hidden z-10 bg-gray-200 rounded-lg shadow w-60 absolute left-1/2 translate-y-2/3">
                          <ul class="h-28 overflow-y-auto text-gray-700" aria-labelledby="dropdownUsersButton">
                            <li>
                              <a href="https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCListaBienes.aspx?del=39&muni=24&rc1=${
                                respuesta[0].referenCiaCatastral1
                              }&rc2=${
          respuesta[0].referenCiaCatastral2
        }" target="_blank" class="flex items-center px-4 py-4 rounded-lg hover:bg-gray-300">
                              <img class="w-6 h-6 me-2 rounded-sm" src="./iconos/oficinavirtualcatastrologohome.webp" alt="Web catastro">
                              Web del catastro
                              </a>
                              </li>
                            <li>
                              <a href="https://www.google.com/maps/search/${
                                arrCoordenadasGTM[1]
                              },${
          arrCoordenadasGTM[0]
        }/data=!3m1!1e3" target="_blank" class="flex items-center px-4 py-4 hover:bg-gray-300 rounded-lg">
                                <img class="w-6 h-6 me-2 rounded-sm" src="./iconos/mapsLogo.webp" alt="Google maps">
                                Google Maps
                                </a>
                                </li>                          
                            <li class="flex items-center hover:bg-gray-300 rounded-lg">
                            <button id="botonCopiarCoordenadas" class="flex flex-row px-4 py-4 w-full">
                            <svg class="me-2 rounded-sm" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
                            <title>ic_fluent_copy_24_regular</title>
                            <g id="🔍-Product-Icons" stroke="none" stroke-width="2" fill="none" fill-rule="evenodd">
                              <g id="ic_fluent_copy_24_regular" fill="#212121" fill-rule="nonzero">
                                <path d="M5.50280381,4.62704038 L5.5,6.75 L5.5,17.2542087 C5.5,19.0491342 6.95507456,20.5042087 8.75,20.5042087 L17.3662868,20.5044622 C17.057338,21.3782241 16.2239751,22.0042087 15.2444057,22.0042087 L8.75,22.0042087 C6.12664744,22.0042087 4,19.8775613 4,17.2542087 L4,6.75 C4,5.76928848 4.62744523,4.93512464 5.50280381,4.62704038 Z M17.75,2 C18.9926407,2 20,3.00735931 20,4.25 L20,17.25 C20,18.4926407 18.9926407,19.5 17.75,19.5 L8.75,19.5 C7.50735931,19.5 6.5,18.4926407 6.5,17.25 L6.5,4.25 C6.5,3.00735931 7.50735931,2 8.75,2 L17.75,2 Z M17.75,3.5 L8.75,3.5 C8.33578644,3.5 8,3.83578644 8,4.25 L8,17.25 C8,17.6642136 8.33578644,18 8.75,18 L17.75,18 C18.1642136,18 18.5,17.6642136 18.5,17.25 L18.5,4.25 C18.5,3.83578644 18.1642136,3.5 17.75,3.5 Z" id="🎨-Color"></path>
                              </g>
                            </g>
                            </svg>
                            Copiar coordenadas
                            </button> 
                            </li>
                            
                                </ul>
                        </div>

                    </div>
                </div>
              </div>
            </div>
          </div>
          `;

          const urlImagenPorReferenciaCatastral = (referenciaCatastral) => {
            // Hago una petición al servidor para obtener el listado de imagenes a partir de la referencia catastral
            const url = `./Request/enc/cargaListadoImagenesCifrado.php`;
          
            const xhr = new XMLHttpRequest();
            xhr.open("POST", url);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            xhr.send(`referenciaCatastral=${referenciaCatastral}`);
          
            return new Promise((resolve, reject) => {
              xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                  if (xhr.status === 200) {
                    const imagenes = JSON.parse(xhr.responseText);
                    // Creo un array con los nombres de las imagenes
                    const nombresImagenes = imagenes.map((imagen) => imagen.nombreImagen);
          
                    // Creo un array de promesas para cargar cada imagen
                    const promesasCargaImagenes = nombresImagenes.map((nombreImagen) => {
                      return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = `./Ibifot/${nombreImagen}`;
                        img.onload = function () {
                          resolve(nombreImagen); // Resuelve la promesa cuando la imagen se carga correctamente
                        };
                        img.onerror = function () {
                          resolve(null); // Resuelve la promesa con null cuando la imagen no se carga correctamente
                        };
                      });
                    });
          
                    // Espero a que todas las promesas de carga de imágenes se resuelvan
                    Promise.all(promesasCargaImagenes).then((resultados) => {
                      // Filtrar los nombres de imágenes que se cargaron correctamente (resultados no son null)
                      const nombresImagenesCargadas = resultados.filter((nombre) => nombre !== null);
                      resolve(nombresImagenesCargadas);
                    });
                  } else {
                    reject("Error");
                  }
                }
              };
            });
          };

        //Cuando se resuelva la promesa, añado al divImagen la imagen
        urlImagenPorReferenciaCatastral(referenciaCatastral).then(
          (imagenes) => {
            if (imagenes.length !== 0) {
              let controles = "";

              if (imagenes.length > 1) {
                controles = `
                <div class="flex justify-around pt-2">
                  <button id="botonAnteriorModal1" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 mr-2 rounded-lg w-16 h-8 flex items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
                      </svg>
                  </button>
                  <button id="botonSiguienteModal1" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 ml-2 rounded-lg w-16 h-8 flex items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                      </svg>
                  </button>
                </div>`;
              }

              document.getElementById(
                "divImagen"
              ).innerHTML = `<img src="./Ibifot/${imagenes[0]}" alt="Imagen parcela">`;
              document.getElementById("divImagen").insertAdjacentHTML("beforeend", controles);
              
              //Al hacer click en el boton siguiente, se muestra la siguiente imagen
              if(document.getElementById("botonSiguienteModal1") !== null) {
                document.getElementById("botonSiguienteModal1").addEventListener("click", () => {
                  const imagen = document.getElementById("divImagen").querySelector("img");
                  const srcImagen = imagen.src;
                  const nombreImagen = srcImagen.substring(srcImagen.lastIndexOf("/") + 1);

                  const indiceImagen = imagenes.indexOf(nombreImagen);

                  if (indiceImagen < imagenes.length - 1) {
                    imagen.src = `./Ibifot/${imagenes[indiceImagen + 1]}`;
                  } else {
                    imagen.src = `./Ibifot/${imagenes[0]}`;
                  }
                });

                //Al hacer click en el boton anterior, se muestra la imagen anterior
                document.getElementById("botonAnteriorModal1").addEventListener("click", () => {
                  const imagen = document.getElementById("divImagen").querySelector("img");
                  const srcImagen = imagen.src;
                  const nombreImagen = srcImagen.substring(srcImagen.lastIndexOf("/") + 1);

                  const indiceImagen = imagenes.indexOf(nombreImagen);

                  if (indiceImagen > 0) {
                    imagen.src = `./Ibifot/${imagenes[indiceImagen - 1]}`;
                  } else {
                    imagen.src = `./Ibifot/${imagenes[imagenes.length - 1]}`;
                  }
                }
                );
              }

            }

            //Compruebo que dentro del divImagen haya una imagen
            if (document.getElementById("divImagen").querySelector("img")) {
            //Al hacer click en la imagen, se lanza un console.log
            document
              .getElementById("divImagen").querySelector("img")
              .addEventListener("click", () => {
                //Compruebo que dentro del divImagen haya una imagen
                if (document.getElementById("divImagen").querySelector("img")) {

                const arrImagenes =
                  urlImagenPorReferenciaCatastral(referenciaCatastral);

                //Si la promesa se resuelve correctamente, muestro las imagenes
                arrImagenes.then((imagenes) => {
                  //Variable que guarda el total de imagenes
                  const totalImagenes = imagenes.length;

                  //En caso de tener solo una imagen se guarda una variable vacia, en caso de tener más se guardan los controles para pasar de una imagen a otra
                  let controles = "",
                    controlesGirar = "";

                  if (totalImagenes > 1) {
                    controles = `
        <div class="flex justify-around pt-2">
          <button id="botonAnteriorModal" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 mr-2 rounded-lg w-20 h-12 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
              </svg>
          </button>
          <button id="botonSiguienteModal" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 ml-2 rounded-lg w-20 h-12 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
              </svg>
          </button>
        </div>`;
                  }

                  controlesGirar = `
      <div class="flex justify-around pt-2">
        <button id="botonGirarIzquierda" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 mr-2 rounded-lg w-20 h-12 flex items-center justify-center">
              <svg width="32px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 20.5C17.1944 20.5 21 16.6944 21 12C21 7.30558 17.1944 3.5 12.5 3.5C7.80558 3.5 4 7.30558 4 12C4 13.5433 4.41128 14.9905 5.13022 16.238M1.5 15L5.13022 16.238M6.82531 12.3832L5.47107 16.3542L5.13022 16.238" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <button id="botonGirarDerecha" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 ml-2 rounded-lg w-20 h-12 flex items-center justify-center">
              <svg width="800px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.5 20.5C6.80558 20.5 3 16.6944 3 12C3 7.30558 6.80558 3.5 11.5 3.5C16.1944 3.5 20 7.30558 20 12C20 13.5433 19.5887 14.9905 18.8698 16.238M22.5 15L18.8698 16.238M17.1747 12.3832L18.5289 16.3542L18.8698 16.238" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
        </button>
      </div>`;

                  //Creo el modal
                  const modal = `
            <!-- Main modal -->
            <div id="modalInfoParcelaImagen" tabindex="-1" class="fixed inset-0 flex items-center justify-center z-50 mx-2">
              <div class="bg-white rounded-lg shadow-lg w-full max-w-2xl">
                <!-- Modal content -->
                <div class="p-4 md:p-5">
                  <!-- Modal header -->
                  <div class="flex items-center justify-between border-b pb-4">
                    <h3 class="text-xl font-semibold text-gray-900">
                      Referencia catastral: ${referenciaCatastral}
                    </h3>
                    <button type="button" id="cerrarModalImagen" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                      <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                      </svg>
                      <span class="sr-only">Close modal</span>
                    </button>
                  </div>
                  <!-- Modal body -->
                  <div id="divImagenesYControles" class="h-[480px] p-1 md:p-5 space-y-4 flex items-center justify-center flex-col">
                    <img id="modalImagen" class="h-[380px]" src="./Ibifot/${imagenes[0]}" alt="imagen_parcela_seleccionada" style="object-fit: contain;" />
                    <div class="flex flex-row w-full justify-around">
                      ${controles}
                      ${controlesGirar}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          `;

                  //Muestro el modal por encima de todo el demas contenido
                  document.body.insertAdjacentHTML("beforeend", modal);

                  // Al pulsar el botón de cerrar modal, se elimina el modal
                  document
                    .getElementById("cerrarModalImagen")
                    .addEventListener("click", () => {
                      document
                        .getElementById("modalInfoParcelaImagen")
                        .remove();
                    });

                  // Al pulsar el botón siguiente, se muestra la siguiente imagen
                  if (document.getElementById("botonSiguienteModal") !== null) {
                    document
                      .getElementById("botonSiguienteModal")
                      .addEventListener("click", () => {
                        const imagen = document.getElementById("modalImagen");
                        const srcImagen = imagen.src;
                        const nombreImagen = srcImagen.substring(
                          srcImagen.lastIndexOf("/") + 1
                        );

                        const indiceImagen = imagenes.indexOf(nombreImagen);

                        if (indiceImagen < imagenes.length - 1) {
                          imagen.src = `./Ibifot/${
                            imagenes[indiceImagen + 1]
                          }`;
                        } else {
                          imagen.src = `./Ibifot/${imagenes[0]}`;
                        }
                      });

                    // Al pulsar el botón anterior, se muestra la imagen anterior
                    document
                      .getElementById("botonAnteriorModal")
                      .addEventListener("click", () => {
                        const imagen = document.getElementById("modalImagen");
                        const srcImagen = imagen.src;
                        const nombreImagen = srcImagen.substring(
                          srcImagen.lastIndexOf("/") + 1
                        );

                        const indiceImagen = imagenes.indexOf(nombreImagen);

                        if (indiceImagen > 0) {
                          imagen.src = `./Ibifot/${
                            imagenes[indiceImagen - 1]
                          }`;
                        } else {
                          imagen.src = `./Ibifot/${
                            imagenes[imagenes.length - 1]
                          }`;
                        }
                      });
                  }

                  document
                    .getElementById("botonGirarIzquierda")
                    .addEventListener("click", () => {
                      const imagen = document.getElementById("modalImagen");
                      const currentRotation =
                        parseInt(imagen.dataset.rotation) || 0;
                      const anchoImagen = imagen.width;
                      const escala = 380 / anchoImagen;
                      const newRotation = (currentRotation - 90) % 360;

                      if (
                        newRotation === 0 ||
                        newRotation === -180 ||
                        newRotation === 180
                      ) {
                        imagen.style.transform = `rotate(${newRotation}deg) scale(1)`;
                        imagen.dataset.rotation = newRotation;
                      } else {
                        imagen.style.transform = `rotate(${newRotation}deg) scale(${escala})`;
                        imagen.dataset.rotation = newRotation;
                      }
                    });

                  // Al pulsar el botón de girar a la derecha, se gira la imagen 90 grados a la derecha
                  document
                    .getElementById("botonGirarDerecha")
                    .addEventListener("click", () => {
                      const imagen = document.getElementById("modalImagen");
                      const currentRotation =
                        parseInt(imagen.dataset.rotation) || 0;
                      const anchoImagen = imagen.width;
                      const escala = 380 / anchoImagen;
                      const newRotation = (currentRotation + 90) % 360;

                      if (
                        newRotation === 0 ||
                        newRotation === -180 ||
                        newRotation === 180
                      ) {
                        imagen.style.transform = `rotate(${newRotation}deg) scale(1)`;
                        imagen.dataset.rotation = newRotation;
                      } else {
                        imagen.style.transform = `rotate(${newRotation}deg) scale(${escala})`;
                        imagen.dataset.rotation = newRotation;
                      }
                    });
                });
              }
              }); 
            }
          }
        );

        // Añado el modal al DOM
        document.body.insertAdjacentHTML("beforeend", modal);

        // Añado el evento click al botón de cerrar el modal
        document.getElementById("cerrarModal").addEventListener("click", () => {
          document.getElementById("modalInfoParcela").remove();
        });

        // Añado el evento click al botón modalVerDibujo
        document.getElementById("modalVerDibujo").addEventListener("click", () => {
          //Obtengo el valor de referenciaCatastralBModal
          const referenciaCatastral = document.getElementById("referenciaCatastralBModal").innerText;
          console.log(referenciaCatastral);

          //Cierro el modal actual
          document.getElementById("modalInfoParcela").remove();

          //Establezco el valor de valorReferenciaCatastral a referenciaCatastral
          document.getElementById("valorReferenciaCatastral").value = referenciaCatastral;

          //Simulo un click en el botón de enviar
          document.getElementById("enviar").click();
          
          //Simulo un click en el botón de id alternarOrtofotoPlano
          document.getElementById("alternarOrtofotoPlano").click();
        });

        // Añado el evento click al botón de más ver más...
        document
          .getElementById("dropdownUsersButton")
          .addEventListener("click", () => {
            document.getElementById("dropdownUsers").classList.toggle("hidden");
          });
          
          const avisoCorrecto = `
          <div class="absolute top-[68px] left-1/2 transform -translate-x-1/2" id="avisoCorrecto">   
            <div class="flex items-center p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 role="alert">
              <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Copiado con éxito</span>
              <div>
                Se han copiado las coordenadas al portapapeles.
              </div>
            </div>
          </div>
          `;
          
        // Añado el evento click al botón de copiar coordenadas
        document
          .getElementById("botonCopiarCoordenadas")
          .addEventListener("click", () => {
            const coordenadas = `${arrCoordenadasGTM[1]},${arrCoordenadasGTM[0]}`;
            if (navigator.clipboard && navigator.clipboard.writeText) {
              navigator.clipboard.writeText(coordenadas)
              .then(() => {
                document.body.insertAdjacentHTML("beforeend", avisoCorrecto);

                setTimeout(() => {
                  document.getElementById("avisoCorrecto").remove();
                }, 3000);
          
                })
                .catch((error) => {
                  console.error("Error al copiar texto al portapapeles:", error);
                });
            } else {
              console.error("El navegador no admite la API del portapapeles");
            }


          });

        // Elimino el spinner
        document.getElementById("spinner").remove();

        /* //Hago que el modal sea draggable
          const modalInfoParcela = document.getElementById("modalInfoParcela");

          let pos1 = 0,
            pos2 = 0,
            pos3 = 0,
            pos4 = 0;

          const dragMouseDown = (e) => {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
          };

          const elementDrag = (e) => {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            modalInfoParcela.style.top = modalInfoParcela.offsetTop - pos2 + "px";
            modalInfoParcela.style.left = modalInfoParcela.offsetLeft - pos1 + "px";
          };

          const closeDragElement = () => {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
          };

          document.getElementById("modalInfoParcela").addEventListener("mousedown", dragMouseDown);
 */

        /* }).catch((error) => {
          console.log(error);
        }); */
      } else {
      }
    } else if (xhr.status !== 200) {
      alert("Request failed.  Returned status of " + xhr.status);
    }
  };

  let spinner = ` 
    <div role="status" id="spinner">
    <svg aria-hidden="true" class="w-10 h-10 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
    </div>
  `;

  //Añado el spinner al body y lo pongo en el centro usando clases de tailwind
  document.body.insertAdjacentHTML("beforeend", spinner);

  //Le añado las clases para que se vea en el centro
  document
    .getElementById("spinner")
    .classList.add(
      "absolute",
      "top-1/2",
      "left-1/2",
      "transform",
      "-translate-x-1/2",
      "-translate-y-1/2",
      "z-50"
    );

  xhr.send(
    `x=${simbolos.values_.geometry.flatCoordinates[0].toString()}&y=${simbolos.values_.geometry.flatCoordinates[1].toString()}`
  );
}