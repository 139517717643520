//Funcion que devuelve el geojson de una referencia catastral
export default function geojsonPorReferenciaCatastral(referenciaCatastral) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(
            "POST",
            "./Request/enc/geojsonPorReferenciaCatastralCifrado.php"
        );
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.onload = function () {
            if (xhr.status === 200) {
                const respuesta = JSON.parse(xhr.responseText);
                if (respuesta) {
                    resolve(respuesta);
                } else {
                    reject("No se ha encontrado el geojson");
                }
            } else if (xhr.status !== 200) {
                reject("Request failed.  Returned status of " + xhr.status);
            }
        };
        xhr.send(`referenciaCatastral=${referenciaCatastral}`);
    });
    
};

