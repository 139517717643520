const botonEsconderHerramientas = document.getElementById("esconderHerramientas");

botonEsconderHerramientas.addEventListener("click", function () {
   //Escondo el div de id logo-sidebar
    document.getElementById("logo-sidebar").classList.toggle("hidden");

    /* //Cambio el tamaño del mapa de id map
    document.getElementById("map").classList.toggle("ml-40"); */

    //Haz que el map ocupe el 100% del ancho
    document.getElementById("map").classList.toggle("w-full");

    document.getElementById("esconderHerramientas").classList.toggle("ml-44");

    //Giro la imagen del botón 180º
    document.getElementById("esconderHerramientas").classList.toggle("rotate-180");

});

//Esconde el menú en resoluciones bajas
window.addEventListener("load", function () {
    if (window.innerWidth < 640) {
        botonEsconderHerramientas.click();
    }
})