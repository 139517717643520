/* Añade options para el datalist de id tiposReferenciaCatastral */

const cargaReferenciasCatastrales = () => {
  const tipoReferenciaCatastral = document.getElementById(
    "tiposReferenciaCatastral"
  );

  const xhr = new XMLHttpRequest();
  xhr.open(
    "POST",
    "./Request/enc/cargaReferenciasCatastralesCifrado.php"
  );
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.onload = function () {
    if (xhr.status === 200) {
      const respuesta = JSON.parse(xhr.responseText);

      //Añado los options al datalist
      respuesta.forEach((element) => {
        const option = document.createElement("option");
        option.value = `${element.referenciaCatastral1}${element.referenciaCatastral2}`;
        tipoReferenciaCatastral.appendChild(option);
      });
    } else if (xhr.status !== 200) {
      alert("Request failed.  Returned status of " + xhr.status);
    }
  };

  xhr.send(`tiposReferenciaCatastral=1`);
};

const cargaNombresDeVias = () => {
  const nombreVia = document.getElementById("nombresVias");

  const xhr = new XMLHttpRequest();
  xhr.open(
    "POST",
    "./Request/enc/cargaNombresDeViasCifrado.php"
  );
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.onload = function () {
    if (xhr.status === 200) {
      const respuesta = JSON.parse(xhr.responseText);

      //Añado los options al datalist
      respuesta.forEach((element) => {
        const option = document.createElement("option");
        option.value = element.nombreCalle;
        nombreVia.appendChild(option);
      });
    } else if (xhr.status !== 200) {
      alert("Request failed.  Returned status of " + xhr.status);
    }
  };

  xhr.send(`nombresVias=1`);
};

//Espero a que se cargue el DOM y ejecuto las funciones
export default function iniciar() {
  document.addEventListener("DOMContentLoaded", cargaReferenciasCatastrales);
  document.addEventListener("DOMContentLoaded", cargaNombresDeVias);
}
